(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('routeStopsService', routeStopsService);

    routeStopsService.$inject = ['$q', '$http', '$rootScope', '$log', 'serviceUrls', 'authService', 'cacheService', 'RouteStop', 'commonUtil', 'updateQueue', 'appVersion', 'appSettings', 'settingsService'];

    function routeStopsService($q, $http, $rootScope, $log, serviceUrls, authService, cacheService, RouteStop, commonUtil, updateQueue, appVersion, appSettings, settingsService) {
        var cachePrefix = '_routeStops_',
            getRouteLocks = {},
            getRouteCalls = {},
            getReportedRouteLocks = {},
            getReportedRouteCalls = {},
            reportedRouteCachePrefix = '_reportedRouteStops_',
            routeStopFilterByCachePrefix = '_routeStopFilterBy',
            routeStopsSplitViewStatusCachePrefix = 'routeStops_splitViewStatus',
            routeStopSortByCachePrefix = '_routeStopSortBy',
            hubConnection;

        var routeStopFilterType, routeStopsSplitViewStatus, routeStopSortType;

        var routeStopsService = {
            deleteLocalRoute: deleteLocalRoute,
            deleteRouteRequest: deleteRouteRequest,
            getContainerDetails: getContainerDetails,
            getFilterBy: getFilterBy,
            getLocalRouteStop: getLocalRouteStop,
            getReportedRoute: getReportedRoute,
            getRoute: getRoute,
            getRouteLinesInRadius: getRouteLinesInRadius,
            getSplitViewStatus: getSplitViewStatus,
            getSortBy: getSortBy,
            queueRouteDelete: queueRouteDelete,
            saveFilterBy: saveFilterBy,
            saveLocalRouteStop: saveLocalRouteStop,
            saveReportedRouteStops: saveReportedRouteStops,
            saveSplitViewStatus: saveSplitViewStatus,
            saveSortBy: saveSortBy,
            updateRouteStop: updateRouteStop,
            updateRouteStopRequest: updateRouteStopRequest,
            addRouteStopToLocalRoute: addRouteStopToLocalRoute,
            updateRouteStopCoords: updateRouteStopCoords,
            updateUnitCoordinates: updateUnitCoordinates,
            syncWorkOrder: syncWorkOrder
        };

        initService();

        return routeStopsService;

        function initService() {
            cacheService.get(routeStopFilterByCachePrefix).then(function (filterType) {
                routeStopFilterType = filterType || 'description';
            });

            cacheService.get(routeStopsSplitViewStatusCachePrefix).then(function (splitViewStatus) {
                routeStopsSplitViewStatus = splitViewStatus || 'horizontal';
            });

            cacheService.get(routeStopSortByCachePrefix).then(function (sortType) {
                routeStopSortType = sortType || 'distance';
            });
        }

        function updateRouteStopCoords(orderId, routeStop) {
            var deferred = $q.defer();

            $http.put('api/routeStops/adjustposition/' + routeStop.routeLineId, { longitude: routeStop.longitude, latitude: routeStop.latitude })
            .then(function (response) {
                if (response && response.data) {
                    return updateRouteStop(orderId, routeStop);
                } else {
                    var errorMsg = response ? "update position failed" : "invalid response";
                    $log.error(errorMsg);
                    return $q.reject(errorMsg);
                }
            })
            .catch(function (err) {
                deferred.reject(err);
            })
            .then(function () {
                deferred.resolve();
            })
            .catch(function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function deleteLocalRoute(orderId, guardFunction) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            var isDeleted = false;
            cacheService.get(cacheKey)
                .then(function (route) {
                    if (guardFunction(route)) {
                        isDeleted = true;
                        return cacheService.remove(cacheKey);
                    }
                })
                .then(function () {
                    deferred.resolve(isDeleted);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function deleteRouteRequest(orderId) {
            return deleteRoute(orderId);
        }

        function getContainerDetails(orderId, containerId) {
            var deferred = $q.defer();

            $http.get('api/orders/' + orderId + '/containerDetails/' + containerId).then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;

        }

        function getFilterBy() {
            return routeStopFilterType;
        }

        function addRouteStopToLocalRoute(routeStop, orderId) {

            var cacheKey = cachePrefix + orderId;
            var deferred = $q.defer();


            cacheService.addToIn(cacheKey, routeStop, true, 'routeStops').then(function () {
                deferred.resolve();
            },
                function (err) {
                    deferred.reject();
                });
            return deferred.promise;
        }

        function getLocalRouteStop(orderId, routeStopId) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            cacheService.get(cacheKey)
                .then(function (route) {
                    var routeStop = route ? _.find(route.routeStops, { "routeLineId": routeStopId }) : null;
                    deferred.resolve(routeStop);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getReportedRoute(orderId, refreshCache) {
            var deferred = $q.defer();
            var key = orderId;

            if (!getReportedRouteLocks[key]) {
                getReportedRouteLocks[key] = true;

                var reportedRoute;
                var cacheKey = reportedRouteCachePrefix + orderId;

                var request = refreshCache === true
                    ? $http.get('api/orders/' + orderId + '/reportedroute')
                    : Promise.resolve();

                request
                    .then(function (response) {
                        if (response && response.data) {
                            reportedRoute = response.data;
                            return saveReportedRouteStops(orderId, reportedRoute);
                        }
                    })
                    .catch(function () {
                        // ignore
                    })
                    .then(function () {
                        return reportedRoute || cacheService.get(cacheKey);
                    })
                    .then(function (reportedRoute) {
                        if (reportedRoute) {
                            deferred.resolve(reportedRoute);
                        } else {
                            deferred.reject();
                        }
                    })
                    .catch(function () {
                        deferred.reject();
                    })
                    .finally(function () {
                        getReportedRouteLocks[key] = false;
                        var getReportedRouteCall = getReportedRouteCalls[key] ? getReportedRouteCalls[key].pop() : null;

                        if (getReportedRouteCall) {
                            getReportedRoute.apply(null, getReportedRouteCall.args).then(function (result) {
                                getReportedRouteCall.deferred.resolve(result);
                            }, function () {
                                getReportedRouteCall.deferred.reject();
                            });
                        }

                        deferred.resolve(reportedRoute);
                    });
            } else {
                getReportedRouteCalls[key] = getReportedRouteCalls[key] || [];
                getReportedRouteCalls[key].unshift({ args: arguments, deferred: deferred });
            }

            return deferred.promise;
        }

        function syncWorkOrder(orderId, position) {
            var deferred = $q.defer();

            var prefix = cachePrefix + orderId;
            var workOrderRouteExists = false;
            var cachedRoute = undefined;
            cacheService.has(prefix)
                .then(function (routeExists) {
                    if (routeExists) {
                        workOrderRouteExists = routeExists;
                        return cacheService.get(prefix);
                    } else {
                        return getRoute(orderId, position, true);
                    }
                })
                .then((route) => {
                    if (!workOrderRouteExists) {
                        deferred.resolve();
                    } else {
                        cachedRoute = route;
                        return getServerRouteOrderRoute(orderId, position);
                    }
                }).then(serverRoute => {
                    if (serverRoute) {
                        cachedRoute.routeStops = mergeWorkOrderRouteStops(cachedRoute.routeStops, serverRoute.routeStops);
                        return cacheService.set(cachePrefix + orderId, cachedRoute);
                    }
                }).then(() => {
                    deferred.resolve();
                });

            return deferred.promise;
        }

        function mergeWorkOrderRouteStops(cachedRouteStops, serverRouteStops) {
            var retainedCachedStops = _.reject(cachedRouteStops, rs =>
                rs.status === 'uploaded'
                && rs.time < moment().subtract(appSettings.ajourRetainTimeoutInDays, 'days').format());

            var newServerRouteStops = _.reject(serverRouteStops, stop => {
                var rs = _.merge(new RouteStop(), stop);
                return _.some(cachedRouteStops, cs =>
                    cs.routeLineId === rs.routeLineId
                    && rs.sameDate(cs)
                    && rs.sameType(cs)
                );
            });
            return retainedCachedStops.concat(newServerRouteStops);
        }


        function getRoute(orderId, position, orderRoute) {
            var deferred = $q.defer();
            var key = orderId;

            settingsService.getEnablePrefillOnDataButtonOne()
                .then(prefillValue => {
                    if (!getRouteLocks[key]) {
                        getRouteLocks[key] = true;
                        var prefix = cachePrefix + orderId;
                        var route;

                        cacheService.has(prefix)
                            .then(function (exists) {
                                if (exists) {
                                    return cacheService.get(prefix);
                                } else {
                                    return getServerRoute(orderId, position, orderRoute);
                                }
                            })
                            .then(function (route) {
                                if (route) {
                                    if (prefillValue) {
                                        route.routeStops = _.forEach(_.map(route.routeStops, function (routeStop) {
                                            return _.merge(new RouteStop(), routeStop);
                                        }), rs => prefillVolumeToDataButton1Value(rs));
                                    } else {
                                        route.routeStops = _.map(route.routeStops, function (routeStop) {
                                            return _.merge(new RouteStop(), routeStop);
                                        });
                                    }

                                    deferred.resolve(route);
                                }
                            })
                            .catch(function () {
                                deferred.reject();
                            })
                            .finally(function () {
                                getRouteLocks[key] = false;
                                var getRouteCall = getRouteCalls[key] ? getRouteCalls[key].pop() : null;

                                if (getRouteCall) {
                                    getRoute.apply(null, getRouteCall.args).then(function (result) {
                                        getRouteCall.deferred.resolve(result);
                                    }, function () {
                                        getRouteCall.deferred.reject();
                                    });
                                }

                                deferred.resolve(route);
                            });
                    } else {
                        getRouteCalls[key] = getRouteCalls[key] || [];
                        getRouteCalls[key].unshift({ args: arguments, deferred: deferred });
                    }
                });


            return deferred.promise;
        }

        function getRouteLinesInRadius(radius, maxHits, lat, lng) {
            var deferred = $q.defer();

            var proximityDto = {
                radius: radius,
                maxHits: maxHits,
                latitude: lat,
                longitude: lng
            };

            var qStr = '?' + $.param(proximityDto);
            $http.get(serviceUrls.orders + '/routelinesinradius/' + qStr).then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getSplitViewStatus() {
            return routeStopsSplitViewStatus;
        }

        function getSortBy() {
            return routeStopSortType;
        }

        function saveFilterBy(filterBy) {
            routeStopFilterType = filterBy;

            cacheService.set(routeStopFilterByCachePrefix, filterBy);
        }

        function saveLocalRouteStop(orderId, routeStop) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            cacheService.replaceIn(cacheKey, 'routeStops', routeStop, 'routeLineId', routeStop.routeLineId).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function saveReportedRouteStops(orderId, reportedRouteStops) {
            var deferred = $q.defer();

            reportedRouteStops = _.uniqBy(reportedRouteStops, 'routeLineId');

            cacheService.set(reportedRouteCachePrefix + orderId, reportedRouteStops).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function saveSplitViewStatus(splitViewStatus) {
            routeStopsSplitViewStatus = splitViewStatus;

            cacheService.set(routeStopsSplitViewStatusCachePrefix, splitViewStatus);
        }

        function saveSortBy(sortBy) {
            routeStopSortType = sortBy;

            cacheService.set(routeStopSortByCachePrefix, sortBy);
        }

        function queueRouteDelete(orderId) {
            var deferred = $q.defer();

            var uploadAction = {
                id: commonUtil.generateGuid(),
                parameters: [orderId],
                type: 'deleteRoute'
            };

            updateQueue.addUpdateAction(uploadAction)
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function updateRouteStop(orderId, routeStop) {
            var deferred = $q.defer();

            saveLocalRouteStop(orderId, routeStop).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateRouteStopRequest(orderId, routeStopId) {
            var deferred = $q.defer();

            getLocalRouteStop(orderId, routeStopId)
                .then(function (routeStop) {
                    if (routeStop) {
                        $http.put(serviceUrls.orders + '/' + orderId + '/routeStops', [routeStop])
                            .then(function () {
                                routeStop.status = 'uploaded';
                                return saveLocalRouteStop(orderId, routeStop);
                            })
                            .then(function () {
                                $rootScope.$broadcast('routeStopUploaded', routeStop);
                                deleteLocalRoute(orderId,
                                    function (route) {
                                        return _.every(route.routeStops, { status: 'uploaded' });
                                    });
                                deferred.resolve();
                            })
                            .catch(function () {
                                deferred.reject();
                            });
                    } else {
                        $log.error('Cannot upload route stop (id: ' + routeStopId + ') because there is no local route (order: ' + orderId + ')');
                        deferred.resolve();
                    }
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        // private functions
        function deleteRoute(orderId) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            cacheService.remove(cacheKey).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function prefillVolumeToDataButton1Value(routeStop) {
            if (routeStop.wasteType === 'Sl' && routeStop.dataButtons && routeStop.dataButtons.length) {
                routeStop.dataButtons[0].value = routeStop.units[0].volume;
            }
        }

        function getServerRoute(orderId, position, orderRoute) {
            if (orderRoute) {
                return getServerRouteOrderRoute(orderId, position);
            } else {
                return getServerRouteRouteHub(orderId, position);
            }
        }

        function getServerRouteRouteHub(orderId, position) {
            var deferred = $q.defer();

            var route;
            authService.getAuthData('authData').then(function (authData) {
                if (authData) {
                    var options = {
                        logging: signalR.LogLevel.Information,
                        accessTokenFactory: function () {
                            return authData.token;
                        }
                    };

                    hubConnection = new signalR.HubConnectionBuilder()
                        .withUrl('routehub?customerid=' + authData.customerId, options)
                        .build();

                    hubConnection.on("retryTriggered", function () {
                        $rootScope.$broadcast('getRouteRetryTriggered');
                    });

                    hubConnection.start()
                        .then(function () {
                            return hubConnection.invoke("GetRouteWithDynamicOrderData", orderId);
                        })
                        .then(function (response) {
                            if (response) {
                                route = createRouteStops(response, position, authData);
                                return cacheService.set(cachePrefix + orderId, route);
                            }
                        })
                        .then(function () {
                            hubConnection.stop();
                            if (route) {
                                deferred.resolve(route);
                            } else {
                                deferred.reject();
                            }
                        })
                        .catch(function () {
                            deferred.reject();
                        });
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function getServerRouteOrderRoute(orderId, position) {
            var deferred = $q.defer();
            var route;
            authService.getAuthData('authData').then(function (authData) {
                if (authData) {
                    $http.get(serviceUrls.orders + '/' + orderId + '/routeWithDynamicData/')
                        .then(response => {
                            if (response) {
                                route = createRouteStops(response.data, position, authData);
                                return cacheService.set(cachePrefix + orderId, route);
                            }
                        })
                        .catch(() => {
                            deferred.reject("get routestops failed");
                        })
                        .then(() => {
                            deferred.resolve(route);
                        })
                        .catch(() => {
                            deferred.reject("failed to save route in cache");
                        });

                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function createRouteStops(responseData, position, authData) {
            return {
                name: responseData.name,
                routeStops: _.map(responseData.routelines,
                    function (routeline) {
                        var routeStop = new RouteStop(routeline,
                            position,
                            $rootScope.userSettings.dataButtons
                        );
                        routeStop.customerId = authData.customerId;
                        routeStop.appVersion = appVersion;
                        return routeStop;
                    })
            };
        }

        function updateUnitCoordinates(agreementLineId, paSystem, x, y) {
            var deferred = $q.defer();

            $http.put('api/units/adjustposition/' + agreementLineId, { longitude: x, latitude: y, paSystem: paSystem })
                .then(function (response) {
                    if (response && response.status === 200) {
                        deferred.resolve();
                    } else {
                        deferred.reject();
                    }
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();
