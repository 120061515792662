(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('RouteStopsListController', routeStopsListController);

    routeStopsListController.$inject = ['$q', '$window', '$state', '$rootScope', '$scope', '$kWindow', '$log', 'locationService', 'routeStopsService', 'ordersService',
        'callOrdersService', 'messagesService', 'routeStopUpdateChecker', 'articlesService', 'cacheService', 'orderStatusTransitions', 'appSettings', 'settingsService', 'dynamicRouteListener',
        'orderDetailTypesService', 'deviationService', 'deviationModalService', '$timeout', 'bluetoothService', 'botekSensorListener', 'errorReportService', 'printerService', 'googleDirectionsService'];

    function routeStopsListController($q, $window, $state, $rootScope, $scope, $kWindow, $log, locationService, routeStopsService, ordersService,
        callOrdersService, messagesService, routeStopUpdateChecker, articlesService, cacheService, orderStatusTransitions, appSettings, settingsService, dynamicRouteListener,
        orderDetailTypesService, deviationService, deviationModalService, $timeout, bluetoothService, botekSensorListener, errorReportService, printerService, googleDirectionsService) {

        $scope.settings = appSettings.routeStops.list;
        $scope.routeStops = [];
        $scope.filterFunction = null;
        $scope.filteredOrderDetailTypes = [];
        $scope.routeStopsDisplayed = [];
        $scope.infiniteScrollDisabled = $scope.settings.infiniteScrollDisabled;
        $scope.displayCount = $scope.settings.displayCount;
        $scope.displayDistance = $scope.settings.displayDistance;
        $scope.displayIncrement = $scope.settings.displayIncrement;
        $scope.orderId = '';
        $scope.hideSequence = false;
        $scope.userView = '';

        $scope.blockIsClose = false;
        $scope.auto = $rootScope.auto;
        $scope.distanceFilter = $rootScope.detectionFilter;
        $scope.speedFilter = $rootScope.speedFilter;

        $scope.loadMore = loadMore;
        $scope.isClosest = isClosest;
        $scope.handleRouteStopCompleted = handleRouteStopCompleted;
        $scope.openDeviation = openDeviation;
        $scope.routeStopClick = routeStopClick;
        $scope.widthxs = false;
        $scope.widthmd = false;
        $scope.widthcxxs = false;
        $scope.widthcmd = false;
        $scope.orderTypeIconVisible = false;
        $scope.firstDeviation = undefined;

        $scope.isPrinting = false;
        $scope.isPrintingReady = false;
        $scope.printingFailed = false;

        $rootScope.testMovementSimulatorEnabled = appSettings.testMovementSimulatorEnabled;

        var deviationWindow;
        var lastUpcomingRouteStop;
        var deviationList;
        var nextRs = null;
        var disableOpenTwiceDeviation = false;
        var isBotekAutoListening = false;

        settingsService.getUserType().then(function (userType) {
            $rootScope.userType = userType;
        });

        initController();

        $timeout(function () {
            if ($state.params.sortBy === 'sequence') {
                $rootScope.$broadcast('routeStopsSortChanged', 'sequence');
            }
            $rootScope.$broadcast('isClosestBinding', $scope.isClosest);
        }, 2000);

        function initController() {
            settingsService.getLoadProfileInfoData().then(function (loadProfileInfoData) {
                $rootScope.loadProfileInfoData = loadProfileInfoData;
            });
            settingsService.getDeviations().then(function (deviations) {
                deviationList = deviationService.sortDeviations(deviations);
                if (deviationList && deviationList.length > 0) {
                    $scope.firstDeviation = deviationList[0];
                }
            });

            settingsService.getIsOrderTypeIconVisible().then((odtVisible) => {
                $scope.orderTypeIconVisible = odtVisible;
            });

            articlesService.getArticles().then(function (articles) {
                $scope.downloadedArticles = articles;
            });

            if (!$state.params.orderId) {
                $state.go('main.orders');
            }

            $scope.$watch('isOnline', function () {
                if ($rootScope.isOnline) {
                    if ($scope.orderId) {
                        dynamicRouteListener.start($scope.orderId);
                    }
                    errorReportService.startListen();
                }
            });

            //close deviation on browser back with confirm
            $rootScope.$on('$stateChangeStart',
                function (event) {
                    if (deviationWindow) {
                        event.preventDefault();

                        var confirmWindow = $kWindow.open({
                            options: {
                                modal: true,
                                title: 'Advarsel!',
                                draggable: false,
                                movable: false,
                                resizable: false,
                                visible: false,
                                height: 150,
                                width: 350
                            },
                            templateUrl: 'app/routeStops/modals/confirm-back-modal.html',
                            windowTemplateUrl: 'app/shared/modal-base.html',
                            controller: 'confirmBackController'
                        });

                        confirmWindow.result.then(function (confirmResult) {
                            if (confirmResult) {
                                if (deviationWindow) {
                                    deviationWindow.close(false);
                                    deviationWindow = null;
                                }
                            }
                        });

                    }

                });

            $rootScope.$on(' showBusyIndicatorOverRouteStops', (event, message) => showBusyIndicatorOverRouteStops(message));

            $rootScope.$on('hideBusyIndicatorOverRotueStops', () => {
                $rootScope.$broadcast('hideBusyIndicator');
            });

            $rootScope.$on('closeDeviationWindow',
                function () {
                    if (deviationWindow) {
                        deviationWindow.close(false);
                        deviationWindow = null;
                        $rootScope.$broadcast('deviationWindowClosed');
                    }
                });


            $rootScope.$on('openDeviationWindow',
                function (broadCastEvt, evt, routeStop, setToNo) {
                    if (!disableOpenTwiceDeviation) {
                        disableOpenTwiceDeviation = true;
                        setTimeout(() => disableOpenTwiceDeviation = false, 200);
                        openDeviation(evt, routeStop, setToNo);
                    }

                });

            $scope.orderId = $state.params.orderId;

            locationService.getCurrentPosition().then(function (position) {
                loadRouteStops(position);
            }, function () {
                loadRouteStops();
            });

            $scope.$on('getRouteRetryTriggered', function () {
                $rootScope.$broadcast('showBusyIndicator', { message: 'Laster ned... \nRetrying...', buttonText: 'Cancel', buttonType: 'cancelGetRoute' });
            });

            $scope.$on('cancelGetRoute', function () {
                $state.go('main.orders');
            });

            $scope.$on('syncWorkOrders', function () {
                showBusyIndicatorOverRouteStops('Laster ned...');

                $rootScope.syncInProgress = true;
                locationService.getCurrentPosition()
                    .then(function (position) {
                        return routeStopsService.syncWorkOrder($scope.orderId, position);
                    })
                    .then(() => {
                        $rootScope.$broadcast('hideBusyIndicator');
                        loadRouteStops();
                    }).finally(() => {
                        $rootScope.syncInProgress = false;
                    });

            });

            $scope.$on('routeStopLocationChanged', function (evt) {
                locationService.getCurrentPosition().then(function (position) {
                    locationChanged(evt, position, true);   //trigger distance updates and sorting
                });
            });

            $scope.$on('locationChanged', locationChanged);

            $scope.$on('autoChange', function (evt, newValue) {
                if (!newValue) {
                    unattendCloseRouteStops();

                }
                $scope.auto = newValue;
                if ($scope.auto) {
                    //handle corner-case when user turns auto-on near route-stop and no location change would arrive until he is out of range of route-stop
                    locationService.getCurrentPosition().then(function (position) {
                        locationChanged(evt, position, false);
                    });
                }

            });

            $scope.$on('detectionSliderChange', function (evt, newValue) {
                $scope.distanceFilter = newValue;
            });

            $scope.$on('speedSliderChange', function (evt, newValue) {
                $scope.speedFilter = newValue;
            });

            $scope.$on('splitterMoved', function () {
                var width = $(".route-stops-list.page").width();
                const splitterSize = 40;

                $scope.widthcxxs = width < (549 - splitterSize);
                $scope.widthcmd = width < (712 - splitterSize);
                $scope.widthxs = width < (768 - splitterSize);
                $scope.widthmd = width < (1024 - splitterSize);
            });

            $scope.$on('routeStopSelectionChanged', function (eventName, selectionChangedEventParams) {
                _.forIn(selectionChangedEventParams, function (isSelected, routeLineId) {
                    var routeStop = _.find($scope.routeStops, { 'routeLineId': Number(routeLineId) });

                    if (routeStop) {
                        if (!_.includes($scope.routeStopsDisplayed, routeStop)) {
                            $scope.routeStopsDisplayed.unshift(routeStop);
                        }
                        routeStop.isSelected = isSelected;
                    }
                });
            });

            $scope.$on('updateReportedRoutes', function (eventName, reportedRouteStops) {
                routeStopsService.saveReportedRouteStops($scope.orderId, reportedRouteStops);
                handleReportedRoutes(reportedRouteStops, $scope.routeStops).finally(renderRouteStops);
            });

            $scope.$on('routeStopsFilterChanged', function (eventName, filter) {
                var filterValue = filter.value;

                $scope.filterFunction = filterValue.length === 0 ? null : function (routeStop) {
                    var propertyValue = routeStop[filter.option.property];
                    if (filter.option.propertyMapper) propertyValue = filter.option.propertyMapper(propertyValue);

                    switch (filter.option.type) {
                        case 'contains':
                            return propertyValue.toLowerCase().includes(filterValue.toLowerCase());
                        case 'equals':
                            return propertyValue === filterValue;
                        case 'lessThan':
                            return _.toNumber(propertyValue) < _.toNumber(filterValue.replace(',', '.'));
                        case 'greaterThan':
                            return _.toNumber(propertyValue) > _.toNumber(filterValue.replace(',', '.'));
                        default:
                            return false;
                    }
                };

                renderRouteStops();
            });

            $scope.$on('orderDetailTypesFilterChanged', function (eventName, filteredOrderDetailTypes) {
                $scope.filteredOrderDetailTypes = filteredOrderDetailTypes || [];
                renderRouteStops();
            });

            $scope.$on('routeStopsSortChanged', function (eventName, sortBy) {
                sortRouteStops(sortBy);
            });

            $scope.$on('routeStopAddRequest', function (eventName, routeStops) {
                $scope.routeStops = _.concat(routeStops, $scope.routeStops);
                renderRouteStops();
            });

            $scope.$on('openItemTypeReport', openItemTypeReport);
            $scope.$on('optimizeRoute', optimizeRoute);

            initMobileSplitViewStatus();
        }

        function optimizeRoute() {
            if ($rootScope.isOnline) {
                var currentPosition = null;
                var stops = _.orderBy(_.filter($scope.routeStops, rs => rs.latitude && rs.longitude), 'distance');
                locationService.getCurrentPosition()
                    .then((position) => {
                        currentPosition = position;
                        var origin = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }
                        showBusyIndicatorOverRouteStops();
                        return googleDirectionsService.getOptimizedRouteOrder(origin, origin, stops);
                    })
                    .then((waypointOrder) => {
                        for (var i = 0; i < stops.length; i++) {
                            stops[i].sequence = waypointOrder[i] + 1;
                        }

                        var requests = _.map(stops, function (stop) {
                            return routeStopsService.saveLocalRouteStop($scope.orderId, stop);
                        });

                        return $q.all(requests);
                    })
                    .then(() => {
                        return loadRouteStops(currentPosition);
                    })
                    .then(() => {
                        $rootScope.$broadcast('changeSort', 'sequence');
                    })
                    .finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator');
                    });
            }
        }

        function openItemTypeReport() {

            var height = Math.min($window.innerHeight, 500);
            $kWindow.open({
                options: {
                    modal: true,
                    title: 'Rapport',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    maxHeight: height,
                    width: '100%',
                    close: function () {
                        $rootScope.$broadcast('hideBusyIndicator'); //hide indicator when closed with 'x' button
                    }
                },
                templateUrl: 'app/routeStops/modals/item-type-report-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'itemTypeReportModalController',
                resolve: {
                    routeStops: function () {
                        return $scope.routeStops;
                    }
                }
            });
        }

        function showBusyIndicatorOverRouteStops(message) {
            var pos = calculateSpinnerPosition();

            $rootScope.$broadcast('showBusyIndicator', {
                message: message, position:
                {
                    left: pos.spinnerLeft,
                    top: pos.spinnerTop
                }
            });
        }

        function unattendCloseRouteStops() {
            var closeStops = _.filter($scope.routeStops, rs => rs.distance < $scope.distanceFilter);
            if (closeStops.length > 0) {
                //By turning auto mode off, the stop that is currently close will not be completed
                alert('Ved å slå av autoregistrering, vil ikke kvittering på nåværende stoppepunkt bli automatisk registrert.');
                _.forEach(closeStops,
                    stop => {
                        stop.isAttended = false;
                        routeStopsService.updateRouteStop($scope.orderId, stop);
                    });
            }
        }

        function highlightUpcoming(sortBy) {
            if (lastUpcomingRouteStop) {
                lastUpcomingRouteStop.isUpcoming = false;
            }
            if (sortBy === 'sequence') {
                if ($scope.routeStops.length > 0) {
                    nextRs = $scope.routeStops.length === 1
                        ? $scope.routeStops[0]
                        : _.find($scope.routeStops, rs => rs.status === 'uncompleted' && rs.sequence && rs.sequence > 0);
                    if (nextRs)
                    {
                        nextRs.isUpcoming = true;
                        lastUpcomingRouteStop = nextRs;
                    }
                }
            } else {
                nextRs = null;
            }
            handlePulsingRouteStops();
        }

        function sortByOrderDataExpires(routeStops) {
            try {
                return _.sortBy(routeStops, rs => moment(_.find(rs.orderData, { key: "DetailData_Expires" }).value, 'DD.MM.YYYY'));
            } catch (err) {
                return routeStops;
            }
        }

        function sortRouteStops(sortBy) {
            if (sortBy === "orderData.DetailData_Expires") {
                $scope.routeStops = sortByOrderDataExpires($scope.routeStops);
            } else {
                $scope.routeStops = _.sortBy($scope.routeStops, sortBy !== 'fill' ? [sortBy] : [minRemainingFillPercentage]);
            }

            $rootScope.activeSort = sortBy;
            renderRouteStops();
        }

        function initMobileSplitViewStatus() {
            if (!$rootScope.isMobilePortraitListInitialized) {
                var routeStopsPage = $('.route-stops.page.ng-scope');
                if (routeStopsPage.length > 0) {
                    if (routeStopsPage[0].clientWidth < 480) {
                        $rootScope.$broadcast('splitViewRequest', 'list');
                    }
                }
                $rootScope.isMobilePortraitListInitialized = true;
            }
        }

        function appendOrderDetailType(routeStop) {
            if (routeStop.units) {
                if (!_.some(routeStop.units, function (u) { return u.orderDetailTypes === undefined })) {
                    var firstOdt;
                    var anyDifferent;
                    if (routeStop.units.length > 0) { //more units
                        firstOdt = routeStop.units[0].orderDetailTypes[0];
                        var odts = _.flatten(_.map(routeStop.units, function (u) { return u.orderDetailTypes }));
                        anyDifferent = _.some(odts, function (o) { return o !== firstOdt; });
                        if (anyDifferent) {
                            routeStop.isOrderDetailTypeMixed = true;
                        } else {
                            extendRouteStopWithOrderDetailType(routeStop);
                        }
                    } else { //one unit
                        if (routeStop.units[0].orderDetailTypes.length > 0) {
                            firstOdt = routeStop.units[0].orderDetailTypes[0];
                            anyDifferent = _.some(routeStop.units[0].orderDetailTypes,
                                function (o) { return o !== firstOdt; });
                            if (anyDifferent) {
                                routeStop.isOrderDetailTypeMixed = true;
                            } else {
                                extendRouteStopWithOrderDetailType(routeStop);
                            }
                        } else { //1 orderDetailType
                            extendRouteStopWithOrderDetailType(routeStop);
                        }
                    }
                } else {//undefined orderDetailType
                    routeStop.isOrderDetailTypeMixed = true;
                }
            } else {//undefined units
                routeStop.isOrderDetailTypeMixed = true;
            }
        }

        function extendRouteStopWithOrderDetailType(routeStop) {
            var odtId = routeStop.units[0].orderDetailTypes[0];
            routeStop.isOrderDetailTypeMixed = false;
            let odtObj = _.find($scope.orderDetailTypes, odt => odt.orderDetailTypeId === odtId);
            if (odtObj) {
                routeStop.unitOrderDetailTypeName = odtObj.orderDetailTypeDescription;
                routeStop.unitOrderDetailType = odtId;
            }
        }

        function calculateOrderDetailTypesDistribution() {
            if ($rootScope.userType === 'service') {
                if ($rootScope.orderDetailTypes) {
                    _.forEach($rootScope.orderDetailTypes, function (odt) {
                        odt.orderDetailTypeCount = 0;
                    });

                    if ($scope.routeStops) {
                        _.forEach($scope.routeStops, function (rs) {
                            if (rs.units) {
                                _.forEach(rs.units, function (unit) {
                                    if (unit.orderDetailTypes) {
                                        var countedOdts = [];
                                        _.forEach(unit.orderDetailTypes, function (odt) {
                                            countedOdts.push(odt);
                                            if (_.findIndex(countedOdts, odt) === -1) {
                                                var index = _.findIndex($rootScope.orderDetailTypes, ['orderDetailTypeId', odt]);
                                                if (index > -1) {
                                                    $rootScope.orderDetailTypes[index].orderDetailTypeCount++;
                                                }
                                            }
                                        });
                                        appendOrderDetailType(rs);
                                    }
                                });
                            }
                        });
                    }

                    orderDetailTypesService.setOrderDetailTypeWndWasOpened();
                }
            }

            $rootScope.$broadcast('routeStopsLoadCompleted');
        }

        function loadMore(count) {
            count = count || $scope.displayIncrement;

            var filteredRouteStops = _.filter($scope.routeStops, { status: 'uncompleted' });
            if ($scope.filterFunction) {
                filteredRouteStops = _.filter(filteredRouteStops, $scope.filterFunction);
            }

            filteredRouteStops = filterByOrderDetailTypes(filteredRouteStops);

            var addedRouteStops = _.take(_.drop(filteredRouteStops, $scope.displayCount), count);

            $scope.displayCount += count;
            $scope.routeStopsDisplayed.push.apply($scope.routeStopsDisplayed, addedRouteStops);
        }

        function routeStopClick(routeStop) {
            var selectionChangedEventParams = {};

            var unselected = _.filter($scope.routeStops, function (stop) {
                return stop.isSelected && (stop !== routeStop);
            });

            _.forEach(unselected, function (stop) {
                stop.isSelected = false;
                selectionChangedEventParams[stop.routeLineId] = false;
            });

            routeStop.isSelected = !routeStop.isSelected;

            selectionChangedEventParams[routeStop.routeLineId] = routeStop.isSelected;
            $rootScope.$broadcast('routeStopSelectionChanged', selectionChangedEventParams);
        }

        function isClosest(routeStop) {
            if ($state.params.sortBy === 'sequence') {
                if (routeStop === nextRs || routeStop.sequence < 0) {
                    return ($scope.auto && routeStop.isAttended) ||
                        (!$scope.blockIsClose && routeStop.distance < $scope.distanceFilter);
                }
                return false;
            } else {
                return ($scope.auto && routeStop.isAttended) ||
                    (!$scope.blockIsClose && routeStop.distance < $scope.distanceFilter);
            }
        }

        function handleRouteStopCompleted(routeStop) {
            routeStop.status = 'completed';
            routeStop.time = moment().format();
            routeStop.isSelected = false;

            settingsService.getPrinterEnabled().then(printerEnabled => {
                if (printerEnabled && routeStop.successful === 'successful') {
                    $scope.isPrinting = true;
                    printerService.printReceipt(routeStop)
                        .then(() => {
                            $scope.isPrinting = false;
                            $scope.isPrintingReady = true;
                            setTimeout(() => {
                                $scope.isPrintingReady = false;
                            }, 2000);
                        })
                        .catch((errorMsg) => {
                            $scope.isPrinting = false;
                            $scope.printingFailed = true;
                            $scope.errorMsg = errorMsg;
                            setTimeout(() => {
                                $scope.printingFailed = false;
                            }, 2000);
                        });
                }
            });


            _.remove($scope.routeStopsDisplayed, { routeLineId: routeStop.routeLineId });
            $scope.displayCount--;
            loadMore(1);

            locationService.getCurrentPosition()
                .then(function (currentPosition) {
                    routeStop.assignLogData(currentPosition);
                })
                .catch(function (error) {
                    $log.error(error);
                })
                .then(function () {
                    return settingsService.getActiveDriver();
                })
                .then(function (activeDriver) {
                    if (activeDriver) {
                        routeStop.driverId = activeDriver.driverId;
                    }
                    return routeStopsService.updateRouteStop(routeStop.callOrderId || $scope.orderId, routeStop);
                })
                .then(function () {
                    $rootScope.$broadcast('routeStopCompleted', routeStop);
                    checkRouteCompleted(routeStop.callOrderId);
                })
                .catch(function (error) {
                    $log.error(error);
                });
            highlightUpcoming($rootScope.activeSort);
        }



        function addContainerId(routeStop) {
            if ($rootScope.userType === 'service') {
                return " - #" + routeStop.units[0].containerId;
            }
            return "";
        }

        function openDeviation(evt, routeStop, setToNo) {
            evt.stopPropagation();
            evt.preventDefault();

            var options = {
                title: addContainerId(routeStop),
                orderId: routeStop.callOrderId || $scope.orderId,
                routeStop,
                deviationList,
                setToNo: setToNo
            }
            deviationWindow = deviationModalService.create(options);

            deviationWindow.result.then(function (deviationRouteStop) {
                if (deviationRouteStop) {
                    var routeStopIndex = _.findIndex($scope.routeStops, { routeLineId: deviationRouteStop.routeLineId });
                    if (routeStopIndex > -1) {
                        deviationRouteStop.isAutoComplete = "False";
                        $scope.routeStops[routeStopIndex] = deviationRouteStop;

                        if (_.find(deviationRouteStop.units, { message: 'Nei' })) {
                            deviationRouteStop.successful = 'unsuccessful';
                        } else {
                            deviationRouteStop.successful = 'successful';
                        }

                        handleRouteStopCompleted(deviationRouteStop);
                        deviationWindow = null;
                    } else {
                        $log.error('failed to find routestop in routestop list');
                    }
                }
            });

            deviationWindow.result.finally(function () {
                deviationWindow = null;
            });
        }

        //Private functions
        function minRemainingFillPercentage(routeStop) {
            return 100 - routeStop.getMaxFillValue();
        }

        function checkRouteCompleted(callOrderId) {
            if ($scope.routeStops.length > 0 && !callOrderId && !_.some($scope.routeStops, ['status', 'uncompleted'])) {
                kendo.alert("Rute fullført!");

                $rootScope.$broadcast('showBusyIndicator');

                routeStopUpdateChecker.scheduleUpdates($scope.orderId)
                    .then(function () {
                        return ordersService.updateOrderStatus($scope.orderId, orderStatusTransitions.complete);
                    })
                    .then(function () {
                        $state.go('main.orders');
                    });
            } else if (callOrderId && !_.some($scope.routeStops, { status: 'uncompleted', callOrderId: callOrderId })) {
                routeStopUpdateChecker.scheduleUpdates(callOrderId).then(function () {
                    return callOrdersService.updateCallOrderStatus(callOrderId, orderStatusTransitions.complete);
                });
            }
        }

        function locationChanged(evt, position, forceSort) {
            if (position) {
                var accuracy = position.coords ? position.coords.accuracy : null;
                var speed = accuracy && (accuracy <= $scope.settings.accuracyTreshold) ? (position.coords.speed * 3.6) : null;
                var isSlowed = speed !== null && speed < $scope.speedFilter;
                $scope.blockIsClose = $scope.auto && !isSlowed;

                _.forEach($scope.routeStops, function (routeStop) {
                    routeStop.updateDistance(position);

                    var isClose = routeStop.distance < $scope.distanceFilter;
                    if ($scope.auto) {
                        // close
                        if (!routeStop.wasClose && isClose) {
                            routeStop.wasClose = true;

                            routeStopsService.updateRouteStop($scope.orderId, routeStop)
                                .then(function () {
                                    return messagesService.getRouteStopMessage(routeStop.agreementLines[0].agreementId);
                                })
                                .then(function (message) {
                                    if (message) {
                                        $kWindow.open({
                                            options: {
                                                modal: true,
                                                title: 'Meldinger',
                                                draggable: false,
                                                movable: false,
                                                resizable: false,
                                                visible: false,
                                                height: 150,
                                                width: 350
                                            },
                                            templateUrl: 'app/routeStops/modals/route-stop-message-view.html',
                                            windowTemplateUrl: 'app/shared/modal-base.html',
                                            controller: 'RouteStopMessageController',
                                            resolve: {
                                                message: function () {
                                                    return message.text;
                                                }
                                            }
                                        });
                                    }
                                });
                        }
                        // arrived
                        if (isSlowed && !routeStop.isAttended && isClose) {
                            routeStop.isAttended = true;
                            routeStopsService.updateRouteStop($scope.orderId, routeStop);
                        }
                        // left
                        if ($scope.auto && routeStop.status === 'uncompleted' && routeStop.isAttended && !isClose) {
                            stopAutoApproved(null, routeStop);
                        }
                    }

                });

                if ($state.params.sortBy === 'distance') {
                    var routeStopsToCheck = _.slice($scope.routeStopsDisplayed, 0, 20);
                    var needSort = forceSort || _.some(routeStopsToCheck, function (routeStop, index) {
                        if ((index + 1) === routeStopsToCheck.length) return false;
                        return routeStop.distance > routeStopsToCheck[index + 1].distance;
                    });

                    if (needSort) {
                        $scope.routeStops = _.orderBy($scope.routeStops, [$state.params.sortBy]);
                        renderRouteStops();
                    }
                }
                handlePulsingRouteStops();
                handleWeightSensorListening();
            }
        }

        function calculateSpinnerPosition() {
            var routeStopsPage = $('.route-stops.page')[0];
            var sLeft = 0;
            var sTop = 0;
            switch ($state.params.splitView) {
                case "horizontal": {
                    sLeft = routeStopsPage.clientLeft + (routeStopsPage.clientWidth - 40) / 4;
                    sTop = routeStopsPage.clientTop + screen.availHeight / 2;
                    break;
                }
                case "vertical": {
                    sLeft = routeStopsPage.clientLeft + routeStopsPage.clientWidth / 2;
                    sTop = (screen.availHeight - 40) / 4;
                    break;
                }
                case "map": {
                    sLeft = routeStopsPage.clientLeft + 40 + routeStopsPage.clientWidth / 2;
                    sTop = routeStopsPage.clientTop + screen.availHeight / 2;
                    break;
                }
                default: {
                    sLeft = routeStopsPage.clientLeft + routeStopsPage.clientWidth / 2;
                    sTop = routeStopsPage.clientTop + screen.availHeight / 2;
                }
            }

            return { spinnerLeft: sLeft, spinnerTop: sTop };
        }

        function loadRouteStops(position) {
            var deferred = $q.defer();

            var pos = calculateSpinnerPosition();

            $rootScope.$broadcast('showBusyIndicator', {
                message: 'Laster ned...', position:
                {
                    left: pos.spinnerLeft,
                    top: pos.spinnerTop
                }
            });

            var isAjour = $rootScope.userType === 'ajour';

            var requests = [
                callOrdersService.getAddedRouteStops($scope.orderId),
                ordersService.getOrder($scope.orderId),
                routeStopsService.getRoute($scope.orderId, position, isAjour),
                orderDetailTypesService.getSelectedOrderDetailTypes()
            ];

            var route;
            $q.all(requests)
                .then(function (data) {
                    var order = data[1];
                    route = data[2];
                    if (data[0] && data[0].length) route.routeStops = _.concat(data[0], route.routeStops || []);
                    $scope.userView = $rootScope.userType ? $rootScope.userType : 'default';
                    $scope.filteredOrderDetailTypes = $rootScope.userType === 'service' ? data[3] || [] : [];

                    ordersService.updateOrderStatus($scope.orderId, orderStatusTransitions.start);
                    $rootScope.$broadcast('routeLoaded', order.routeName);

                    if (route.routeStops && route.routeStops.length && order.orderType === 6 && $rootScope.isOnline) {
                        dynamicRouteListener.start($scope.orderId);
                        var reloadReportedRouteStops = true;
                        if ($state.params.reloadReportedRouteStops) {
                            reloadReportedRouteStops = $state.params.reloadReportedRouteStops;
                        }
                        return routeStopsService.getReportedRoute($scope.orderId, reloadReportedRouteStops);
                    }
                })
                .then(function (reportedRouteStops) {
                    if (reportedRouteStops) return handleReportedRoutes(reportedRouteStops, route.routeStops);
                })
                .then(function () {
                    var fillSortable = _.some(route.routeStops, function (r) { return r.hasFillValue(); });
                    if (fillSortable && $scope.userView !== 'service') {
                        $rootScope.$broadcast('routeStopsSortCustomized', { action: 'add', sortOption: { displayName: 'Fyll %', value: 'fill' } });
                    }

                    if ($rootScope.userType === "ajour") {
                        $rootScope.$broadcast('routeStopsSortCustomized', { action: 'add', sortOption: { displayName: 'Utføres innen', value: 'orderData.DetailData_Expires' } });
                    }

                    $scope.routeStops = _.sortBy(route.routeStops, [$state.params.sortBy]);

                    handleSequences();
                    handleWasteTypes();
                    renderRouteStops();
                    checkRouteCompleted();
                    calculateOrderDetailTypesDistribution();
                    handlePulsingRouteStops();
                })
                .catch(function () {
                    alert("Nedlasting feilet, men forsøk igjen. Dette kan skyldes manglende lagringsplass.");
                    $state.go('main.orders');
                })
                .finally(function () {
                    $rootScope.$broadcast('hideBusyIndicator');
                    deferred.resolve();
                });

            return deferred.promise;
        }

        function handleWeightSensorListening() {
            if (!isiOSDevice()) {
                if (_.filter($scope.routeStops, rs => rs.distance < $scope.distanceFilter && rs.status === 'uncompleted').length > 0) {
                    if (!isBotekAutoListening) {
                        isBotekAutoListening = true;
                        botekSensorListener.startListening();
                    }
                } else {
                    if (isBotekAutoListening) {
                        isBotekAutoListening = false;
                        botekSensorListener.stopListening();
                    }
                }
            }

        }

        // ReSharper disable once InconsistentNaming
        function isiOSDevice() {
            var platform = navigator.platform;
            return platform === 'iPad' || platform === 'iPhone' || platform === 'iPod' || platform.includes('Mac');
        }

        function stopAutoApproved(evt, routeStop) {
            if (evt) {
                evt.stopPropagation();
                evt.preventDefault();
            }

            routeStop.IsAutoComplete = "True";
            routeStop.successful = 'successful';
            routeStop.message = 'Ja';

            handleRouteStopCompleted(routeStop);
        }

        function handlePulsingRouteStops() {
            var closestRouteStops = $scope.routeStops.filter(function (routeStop) {
                return routeStop.distance < $scope.distanceFilter && routeStop.status === 'uncompleted';
            });
            if (nextRs) {
                closestRouteStops.push(nextRs);
            }
            $rootScope.$broadcast('updatePulsingRouteStops', closestRouteStops);
        }

        function handleReportedRoutes(reportedRouteStops, collection) {
            var deferred = $q.defer();

            var requests = _.map(
                _.filter(
                    _.map(reportedRouteStops, function (reportedRouteStop) {
                        return _.find(collection, { routeLineId: reportedRouteStop.routeLineId });
                    }),
                    function (routeStop) {
                        return routeStop && routeStop.status !== 'uploaded';
                    }),
                function (routeStop) {
                    routeStop.status = 'uploaded';
                    routeStop.successful = 'unknown';
                    return routeStopsService.saveLocalRouteStop($scope.orderId, routeStop);
                });

            $q.all(requests).then(function () {
                checkRouteCompleted();
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        //only show waste types if more than one type is present
        function handleWasteTypes() {
            var isMixed = false;
            var pr = 0, nae = 0, sl = 0;
            for (var i = 0; i < $scope.routeStops.length; i++) {
                var lowerCaseWasteType = $scope.routeStops[i].wasteType ? $scope.routeStops[i].wasteType.toLowerCase() : "pr";
                switch (lowerCaseWasteType) {
                    case "pr": {
                        pr++;
                        break;
                    }
                    case "næ": {
                        nae++;
                        break;
                    }
                    case "sl": {
                        sl++;
                        break;
                    }
                    default:
                        pr++;
                }

                if ((pr && nae) || (pr && sl) || (nae && sl)) {
                    isMixed = true;
                    break;
                }
            }
            $scope.areWasteTypesMixed = isMixed;
        }

        function handleSequences() {
            var hideSequence = $scope.routeStops.length > $scope.settings.showSequenceMaxRouteStopCount ||
                _.some($scope.routeStops, function (r) { return r.sequence && r.sequence.toString().length > $scope.settings.showSequenceMaxLength; });

            if (hideSequence && !$scope.hideSequence) {
                $rootScope.$broadcast('routeStopsSortCustomized', { action: 'remove', sortOption: { value: 'sequence' } });
            }

            if ($rootScope.userType === 'ajour') {
                hideSequence = _.every($scope.routeStops, rs => rs.sequence === 0);
            }

            $scope.hideSequence = hideSequence;
        }

        function renderRouteStops() {
            var filteredRouteStops = _.filter($scope.routeStops, () => true);
            var callOrderRouteStops = _.remove(filteredRouteStops, function (filteredRouteStop) { return filteredRouteStop.hasOwnProperty('callOrderId'); });
            var nearbyRouteStop = _.remove(filteredRouteStops, function (filteredRouteStop) { return filteredRouteStop.hasOwnProperty('isNearbyRouteStop'); });

            filteredRouteStops = _.concat(callOrderRouteStops, filteredRouteStops);
            filteredRouteStops = _.concat(nearbyRouteStop, filteredRouteStops);

            if ($scope.filterFunction) {
                filteredRouteStops = _.filter(filteredRouteStops, $scope.filterFunction);
            }

            filteredRouteStops = filterByOrderDetailTypes(filteredRouteStops);

            var unCompletedFilteredRouteStops = _.filter(filteredRouteStops, { status: 'uncompleted' });
            $scope.routeStopsDisplayed = $scope.infiniteScrollDisabled ? unCompletedFilteredRouteStops : _.take(unCompletedFilteredRouteStops, $scope.displayCount);
            $rootScope.$broadcast('routeStopFilterReady', filteredRouteStops);

            highlightUpcoming($rootScope.activeSort);

        }

        function filterByOrderDetailTypes(filteredRouteStops) {
            var filteredStops = filteredRouteStops;

            if ($scope.filteredOrderDetailTypes && $scope.filteredOrderDetailTypes.length > 0) {
                filteredStops = _.filter(filteredRouteStops, function (routeStop) {
                    return _.some(routeStop.units, function (unit) {
                        return _.intersection($scope.filteredOrderDetailTypes, unit.orderDetailTypes).length > 0;
                    });
                });
            }

            return filteredStops;
        }
    }
})();
