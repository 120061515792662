(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('orderItemTypeService', orderItemTypeService)

    orderItemTypeService.$inject = ['orderItemTypes'];

    function orderItemTypeService(orderItemTypes) {
        var service = {
            getIcon: getIcon,
            getOrderItemType: getOrderItemType,
            isOrderItemTypeOut: isOrderItemTypeOut
        }

        return service;

        function isOrderItemTypeOut(routeStop){
            return getOrderItemType(routeStop) === orderItemTypes.out;
        }

        function getOrderItemType(routeStop){
            var first = routeStop.units[0].orderItemType;
            if (routeStop && routeStop.units && routeStop.units.length) {
                if ((first === orderItemTypes.in && _.some(routeStop.units, u => u.orderItemType === orderItemTypes.out))
                    || (first === orderItemTypes.out && _.some(routeStop.units, u => u.orderItemType === orderItemTypes.in))) {
                    return orderItemTypes.replace;
                } else {
                    return _.some(routeStop.units, u => u.orderItemType !== first) ? orderItemTypes.unknown : first;
                }
            }
            return orderItemTypes.unknown;
        }

        function getIcon(orderType){
            orderType = orderType ? orderType : '';
            switch (orderType) {
                case orderItemTypes.in: {
                    return "fa fa-long-arrow-alt-left fa-1-25x red";
                }
                case orderItemTypes.out: {
                    return "fa fa-long-arrow-alt-right fa-1-25x green";
                }
                case orderItemTypes.replace: {
                    return "fa fa-exchange-alt fa-1-25x";
                }
                case orderItemTypes.rep: {
                    return "fa fa-wrench fa-1-25x";
                }
                case orderItemTypes.inspection: {
                    return "fa fa-search fa-1-25x";
                }
                case orderItemTypes.service: {
                    return "fa fa-toolbox fa-1-25x";
                }
                default: {
                    return '';
                }
            }    
        }
    }
})();