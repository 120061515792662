(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('messagesService', messagesService);

    messagesService.$inject = ['$rootScope', '$q', '$http', '$interval', 'appSettings', 'messageState', 'cacheService',
        'updateQueue', 'commonUtil'];

    function messagesService($rootScope, $q, $http, $interval, appSettings, messageState, cacheService,
        updateQueue, commonUtil) {
        var timer = null;
        var cachePrefix = '_messages_';

        var messagesService = {
            getRouteStopMessage: getRouteStopMessage,
            startMessagePolling: startMessagePolling,
            stopMessagePolling: stopMessagePolling,
            updateMessageStatus: updateMessageStatus,
            updateMessageStatusRequest: updateMessageStatusRequest
        };

        return messagesService;

        function getRouteStopMessage(agreementId) {
            var deferred = $q.defer();

            cacheService.get(cachePrefix).then(function (cachedMessages) {
                cachedMessages = cachedMessages || [];
                var routeStopMessage = _.find(cachedMessages, { agreementId: agreementId });
                deferred.resolve(routeStopMessage);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;

        }

        function startMessagePolling() {
            if (timer == null) {
                timer = $interval(function () {
                    $http.get('api/message/directmessages').then(function (response) {
                        if (response && response.data && response.data.length > 0) {
                            var messages = response.data;

                            cacheService.get(cachePrefix).then(function (cachedMessages) {
                                cachedMessages = cachedMessages || [];

                                _.forEach(messages, function (m) {
                                    if (_.findIndex(cachedMessages, m) === -1) {
                                        cachedMessages.push(m);
                                    }
                                });

                                $rootScope.directMessages = _.orderBy(cachedMessages, ['messageState', 'date'], ['asc', 'desc']);

                                cacheService.set(cachePrefix, $rootScope.directMessages);

                                $rootScope.messageBadgeCount = _.countBy($rootScope.directMessages, function (m) { return m.messageState === messageState.new }).true;

                                if ($rootScope.messageBadgeCount > 0) {
                                    $rootScope.showMessageBadge = true;
                                } else {
                                    $rootScope.showMessageBadge = false;
                                }
                            });
                        }
                    });
                }, appSettings.directMessagePollingIntervalMs);
            }
        }

        function stopMessagePolling() {
            $interval.cancel(timer);
            timer = null;
        }

        function updateMessageStatus(messageId) {
            var deferred = $q.defer();

            cacheService.get(cachePrefix)
                .then(function (messages) {
                    var message = _.find(messages, { 'id': messageId });
                    message.messageState = messageState.readProfile;

                    return cacheService.set(cachePrefix, messages);
                })
                .then(function () {
                    var updateAction = {
                        id: commonUtil.generateGuid(),
                        parameters: [messageId],
                        type: 'updateMessageStatus'
                    };

                    return updateQueue.addUpdateAction(updateAction);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function updateMessageStatusRequest(messageId) {
            var deferred = $q.defer();

            $http.put('api/message/' + messageId + '/status').then(function() {
                    deferred.resolve();
            },
            function() {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
