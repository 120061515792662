(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('updateQueue', updateQueue);

    updateQueue.$inject = ['$q', '$rootScope', '$timeout', 'cacheService'];

    var updateActionTypes = [];

    function updateQueue($q, $rootScope, $timeout, cacheService) {
        var cacheKey = '_updateQueue';
        var lock = false;


        var updateQueue = {
            addUpdateAction: addUpdateAction,
            setUpdateActionTypes: setUpdateActionTypes,
            triggerUpdates: triggerUpdates
        };

        return updateQueue;

        function setUpdateActionTypes(actionTypes) {
            if (actionTypes && actionTypes.length) {
                updateActionTypes = [];
                _.forEach(actionTypes, actionType => updateActionTypes.push(actionType));
            }
        }

        function addUpdateAction(updateAction) {
            var deferred = $q.defer();

            cacheService.prependTo(cacheKey, updateAction).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function triggerUpdates(nested) {
            var deferred = $q.defer();

            if ($rootScope.isOnline && (!lock || nested)) {
                lock = true;
                var updateActions;
                var lastUpdateAction;

                cacheService.get(cacheKey)
                    .then($timeout(250))
                    .then(function (updateQueue) {
                        if (updateQueue) {
                            updateActions = updateQueue.slice();

                            lastUpdateAction = updateActions.pop();
                            if (lastUpdateAction) return callback(lastUpdateAction);
                        }
                    })
                    .then(function () {
                        if (lastUpdateAction) return cacheService.removeFrom(cacheKey, null, 'id', lastUpdateAction.id);
                    })
                    .catch(function () {
                        if (lastUpdateAction && lastUpdateAction.isPostponable) return postponeAction(cacheKey, lastUpdateAction)
                    })
                    .then(function () {
                        if (lastUpdateAction) return triggerUpdates(true);
                    })
                    .then(function () {
                        if (!nested) lock = false;
                        deferred.resolve();
                    })
                    .catch(function () {
                        deferred.reject();
                    })
                    .finally(function () {
                        if (!nested) lock = false;
                    });
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }

        function callback(updateAction) {
            var deferred = $q.defer();

            var updateActionType = _.find(updateActionTypes, { 'type': updateAction.type });

            if (updateActionType) {
                updateActionType.callback.apply(this, updateAction.parameters).then(function () {
                    deferred.resolve();
                }, function () {
                    deferred.reject();
                });
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }

        function postponeAction(cacheKey, lastUpdateAction) {
            var deferred = $q.defer();

            cacheService.removeFrom(cacheKey, null, 'id', lastUpdateAction.id)
                .then(() => {
                    return addUpdateAction(lastUpdateAction)
                })
                .then(() => {
                    deferred.resolve();
                })
                .catch(() => {
                    $log.Error("Failed to add update action" +
                        lastUpdateAction.parameters ? lastUpdateAction.parameters[0] : "no parameter");
                    deferred.reject();
                });

            deferred.resolve();

            return deferred.promise;
        }
    }
})();
