(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('MapController', mapController);

    mapController.$inject = ['$q', '$document', '$state', '$rootScope', '$scope', '$timeout', 'locationService', 'routeStopsService',
        'RouteStop', 'leafletData', 'appSettings', 'cacheService'];

    function mapController($q, $document, $state, $rootScope, $scope, $timeout, locationService, routeStopsService,
        RouteStop, leafletData, appSettings, cacheService) {

        $scope.routeStops = [];
        $scope.markers = {};
        $scope.stopCircleMarkers = {};
        $scope.sequenceNumberMarkers = [];
        $scope.position = null;

        $scope.center = {};
        $scope.tiles = { url: '' };

        $scope.initBaseMap = initBaseMap;
        $scope.drawStopCircles = drawStopCircles;
        $scope.setViewToCurrentPosition = setViewToCurrentPosition;
        $scope.setViewToAvgRouteStops = setViewToAvgRouteStops;

        $scope.googleMap = null;
        $scope.stopMarkers = [];
        $scope.showDirection = showDirection;

        $scope.mapSettings = appSettings.map;
        $scope.projection = $scope.mapSettings.esriLayer.projection;

        if ($rootScope.cachedZoomLevel) {
            $scope.lastZoom = $rootScope.cachedZoomLevel;
        } else {
            $scope.lastZoom = $scope.mapSettings.useEsriLayer ? $scope.mapSettings.esriLayer.center.zoom : $scope.mapSettings.defaultMap.center.zoom;
        }

        $scope.followMe = $scope.mapSettings.followMe;
        $scope.controls = { custom: [] };
        var sequenceNumberIcons = [];
        var currentSort = $state.params.sortBy;
        var pulsingMarkers = null;
        var unitIcons = [];
        var isClosest = null;
        initController();

        function initController() {
            initBaseMap();
            $rootScope.$on('isClosestBinding', (evt, isClosestFn) => {
                isClosest = isClosestFn;
            });
            $scope.$on('locationChanged', locationChanged);
            $scope.$on('locationExpired', deactivateMarker);

            $scope.$on('routeStopLocationChanged', function (evt, routeLineId) {
                leafletData.getMap().then(function (map) {
                    if ($scope.stopCircleMarkers[routeLineId]) {
                        map.removeLayer($scope.stopCircleMarkers[routeLineId]);
                        delete $scope.stopCircleMarkers[routeLineId];
                    }
                });

                drawStopCircles();
            });

            $scope.$on('UnitLocationChanged', function (evt, rs) {
                leafletData.getMap().then(function (map) {
                    addUnitLayer(map);
                });
            });

            $scope.$on('splitterMoved', function () {
                leafletData.getMap().then(function (map) {
                    map.invalidateSize();
                });
            });

            $scope.$on('routeStopSelectionChanged', function (eventName, selectionChangedEventParams) {
                _.forIn(selectionChangedEventParams, function (isSelected, routeLineId) {
                    var routeStopCircle = $scope.stopCircleMarkers[routeLineId];

                    if (routeStopCircle) {
                        routeStopCircle.isSelected = isSelected;
                        if (isSelected) {
                            L.DomUtil.addClass(routeStopCircle._path, 'selected');
                        } else {
                            L.DomUtil.removeClass(routeStopCircle._path, 'selected');
                        }
                    }
                });
                leafletData.getMap().then(map => {
                    if (map) {
                        addUnitLayer(map);
                    }
                });

            });

            $scope.$on('routeStopCompleted', function (eventName, routeStop) {
                routeStopCompleted(routeStop);
            });

            $scope.$on('updateReportedRoutes', function (eventName, reportedRouteStops) {
                routeStopsCompleted(reportedRouteStops);
            });

            locationService.getCurrentPosition().then(function (position) {
                if (position && position.coords) {
                    $scope.position = position;

                    $scope.markers['currentPosition'] = {
                        icon: getIcon(false),
                        lat: Number(position.coords.latitude),
                        lng: Number(position.coords.longitude)
                    };

                    setViewToCurrentPosition(position.coords);
                }
            }).finally(function () {
                if ($state.params.orderId) {
                    var isAjour = $rootScope.userType === 'ajour';
                    routeStopsService.getRoute($state.params.orderId, $scope.position, isAjour)
                        .then(function (route) {
                            if ($scope.routeStops.length === 0) {
                                $scope.routeStops = route.routeStops;
                            }
                            return drawStopCircles();
                        })
                        .then(function () {
                            if (!$scope.position) {
                                setViewToAvgRouteStops();
                            }

                            return routeStopsService.getReportedRoute($state.params.orderId);
                        })
                        .then(function (reportedRouteStops) {
                            routeStopsCompleted(reportedRouteStops);
                        })
                        .catch(function () {
                            // TODO: log
                        });
                }
            });
        }

        function initBaseMap() {
            if ($scope.mapSettings.useEsriLayer) {
                $scope.tiledmapLayer = $scope.mapSettings.esriLayer.tiles;
            } else {
                $scope.tiles = $scope.mapSettings.defaultMap.tiles;
            }

            $scope.center = $scope.mapSettings.useEsriLayer ? $scope.mapSettings.esriLayer.center : $scope.mapSettings.defaultMap.center;

            if ($scope.lastZoom) {
                $scope.center.zoom = $scope.lastZoom;
            }

            $scope.$watch("center.zoom", function (zoom) {
                var labelMinZoom = $scope.mapSettings.labelMinZoomLevel;
                var radius = getRadius(zoom);

                _.forIn($scope.stopCircleMarkers, function (value, key) {
                    var marker = value;
                    marker.setRadius(radius);

                    if ($scope.mapSettings.labelEnabled) {
                        if ($scope.lastZoom < labelMinZoom && labelMinZoom <= zoom) {
                            L.DomUtil.removeClass(marker.getTooltip()._container, 'hidden');
                        } else if (zoom < labelMinZoom && labelMinZoom >= $scope.lastZoom) {
                            L.DomUtil.addClass(marker.getTooltip()._container, 'hidden');
                        }
                    }
                });

                $scope.lastZoom = zoom;
                $rootScope.cachedZoomLevel = zoom;
                cacheService.set("cachedZoomLevel", $rootScope.cachedZoomLevel);
            });

            L.Control.FollowMe = L.Control.extend({
                onAdd: function (map) {
                    var container = L.DomUtil.create('div', 'follow-me-container');
                    var label = L.DomUtil.create('label', '', container);
                    var input = L.DomUtil.create('input', 'follow-me-input', label);

                    input.type = 'checkbox';
                    input.checked = $scope.followMe;
                    input.onclick = function () {
                        $scope.followMe = !$scope.followMe;
                        cacheService.set('_map_followMe', $scope.followMe);
                    };

                    label.appendChild(document.createTextNode('Følg meg'));
                    label.style.color = '#000000';

                    return container;
                }
            });

            $scope.controls.custom.push(new L.Control.FollowMe({ position: 'bottomleft' }));

            cacheService.get('_map_followMe').then(function (followMe) {
                $scope.followMe = _.isBoolean(followMe) ? followMe : $scope.followMe;
                document.getElementsByClassName('follow-me-input')[0].checked = $scope.followMe;
            });

            leafletData.getMap().then(function (map) {
                map.on('moveend', mapPositionChanged);
            });
        }

        function drawStopCircles(withoutSequenceUpdate) {
            var deferred = $q.defer();

            var routeStops = $scope.routeStops;

            leafletData.getMap().then(function (map) {
                //Leave time for the view to load
                $timeout(function () {
                    addRouteStopsToMap(map, routeStops);
                    addUnitLayer(map);
                    if (!withoutSequenceUpdate) {
                        updateSequenceNumberLayer(currentSort, map);
                    }
                    deferred.resolve();
                }, 1000);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function setViewToCurrentPosition(coords) {
            $scope.center = {
                lat: Number(coords.latitude),
                lng: Number(coords.longitude),
                zoom: $scope.center.zoom
            };
        }

        function setViewToAvgRouteStops() {
            var routeStops = $scope.routeStops;

            leafletData.getMap().then(function (map) {
                var maxLat = _.maxBy(routeStops, 'latitude').latitude,
                    minLat = _.minBy(routeStops, 'latitude').latitude,
                    maxLong = _.maxBy(routeStops, 'longitude').longitude,
                    minLong = _.minBy(routeStops, 'longitude').longitude,
                    headerPadding = $document.find("header").height() + 10,
                    footerPadding = $document.find("footer").height() + 10;

                map.fitBounds(L.latLngBounds(L.latLng(minLat, maxLong), L.latLng(maxLat, minLong)), { paddingTopLeft: L.point(0, headerPadding), paddingBottomRight: L.point(0, footerPadding) });
            });
        }

        function showDirection(startPos, destinationPos) {
            var directionsService = new google.maps.DirectionsService,
                directionsDisplay = new google.maps.DirectionsRenderer;

            directionsDisplay.setMap($scope.googleMap);

            directionsService.route({
                origin: startPos.lat.toString() + ',' + startPos.lng.toString(),
                destination: destinationPos.lat.toString() + ',' + destinationPos.lng.toString(),
                travelMode: 'DRIVING'
            }, function (response, status) {
                if (status === 'OK') {
                    directionsDisplay.setDirections(response);
                }
            });
        }

        //Private functions
        function addRouteStopsToMap(map, routeStops) {
            var routeStopsToAdd = _.take(_.orderBy(routeStops, getStopDistanceFromMapCenter), $scope.mapSettings.maxMarkerCount);

            //Add route stops that are not yet added to the map
            for (var i = 0; i < routeStopsToAdd.length; ++i) {
                if (!(routeStopsToAdd[i].routeLineId in $scope.stopCircleMarkers)) {
                    var stopCircleMarker = addRouteStopToMap(map, routeStopsToAdd[i]);
                    $scope.stopCircleMarkers[routeStopsToAdd[i].routeLineId] = stopCircleMarker;
                }
            }

            for (var key in $scope.stopCircleMarkers) {
                if ($scope.stopCircleMarkers.hasOwnProperty(key)) {
                    if (key) {
                        var routeStop = _.find(routeStopsToAdd, { routeLineId: parseInt(key) });
                        if (routeStop === undefined) {
                            map.removeLayer($scope.stopCircleMarkers[key]);
                            delete $scope.stopCircleMarkers[key];
                        }
                    }
                }
            }
        }

        $scope.$on('updatePulsingRouteStops',
            function (event, pulsingRouteStops) {
                drawPulsingFadingMarkers(pulsingRouteStops);
            });

        $scope.$on('routeStopFilterReady', function (event, filteredRouteStops) {
            $scope.routeStops = filteredRouteStops;
            drawStopCircles();
        });

        $scope.$on('routeStopsSortChanged', function (event, sortBy) {
            currentSort = sortBy ? sortBy : 'sequence';
        });

        //container layer
        function addUnitToMap(rs, map, iconSize) {
            if (rs.system === 'S') {
                if (rs.isSelected || (isClosest && isClosest(rs))) {
                    var sizeStyle = 'style="height:' + iconSize + 'px;width:' + iconSize + 'px;"';
                    _.forEach(rs.units,
                        u => {
                            var icon = L.marker([u.y, u.x],
                                {
                                    icon: L.divIcon({
                                        html: '<div class="septic-container-icon"' + sizeStyle + '></div>'
                                    })
                                });
                            if (u.agreementLines[0]) {
                                unitIcons.push(icon);
                                icon.addTo(map);
                            }

                        });
                }

            }
        }

        function addUnitLayer(map) {
            if ($rootScope.userType === 'default') {
                if (unitIcons) {
                    var removedIcons = [];
                    _.forEach(unitIcons, u => {
                        map.removeLayer(u);
                        removedIcons.push(u);
                    });
                    unitIcons = unitIcons.filter(u => !removedIcons.includes(u));
                }

                var size = getSize(map._zoom);
                if ($scope.routeStops && $scope.routeStops.length > 0) {
                    _.forEach($scope.routeStops, rs => addUnitToMap(rs, map, size));
                }
            }
        }

        //Sequence layer
        function addSequenceNumberToMap(rs, map) {
            var icon = L.marker([rs.latitude, rs.longitude],
                {
                    icon: L.divIcon({
                        html: '<div class="sequence-number">' + rs.sequence + '</div>'
                    })
                });
            sequenceNumberIcons.push(icon);
            icon.addTo(map);
        }

        function updateSequenceNumberLayer(sortBy, map) {
            if (map) {
                recreateSequenceLayer(sortBy, map);
            } else {
                leafletData.getMap().then(function (map) {
                    recreateSequenceLayer(sortBy, map);
                });
            }
        }

        function recreateSequenceLayer(sortBy, map) {
            if (sequenceNumberIcons) {
                sequenceNumberIcons.forEach(icon => map.removeLayer(icon));
            }

            if (sortBy === 'sequence' && map._zoom > appSettings.maxZoomLevelShowingSequence) {
                if ($scope.routeStops && $scope.routeStops.length > 0) {
                    leafletData.getMap().then(function (map) {
                        var displayed = 0;
                        for (var i = 0; i < $scope.routeStops.length && displayed < appSettings.displayedSequenceNumberCount; i++) {
                            let rs = $scope.routeStops[i];
                            if (rs.sequence && rs.sequence > 0 && rs.status === 'uncompleted') {
                                addSequenceNumberToMap(rs, map);
                                displayed++;
                            }
                        }
                    });

                }
            }
        }
        
        function drawPulsingFadingMarkers(pulsingRouteStops) {
            var nonPulsingCircles = _.filter($scope.stopCircleMarkers, marker => marker.isPulsing);

            if (nonPulsingCircles && nonPulsingCircles.length > 0) {
                _.forEach(nonPulsingCircles, rs => {
                    if (rs && rs._path) {
                        L.DomUtil.removeClass(rs._path, 'selected');
                        rs.isPulsing = false;
                    }
                });
            }

            var pulsingCircles = _.map(pulsingRouteStops, rs => $scope.stopCircleMarkers[rs.routeLineId]);

            if (pulsingCircles && pulsingCircles.length > 0) {
                _.forEach(pulsingCircles, rs => {
                    if (rs && rs._path) {
                        L.DomUtil.addClass(rs._path, 'selected');
                        rs.isPulsing = true;
                    }
                });
            }
        }

        function specifyFillColor(routeStop) {
            if (routeStop.status !== "uncompleted")
                return '#02ac4a';

            var lowerCaseWasteType = routeStop.wasteType ? routeStop.wasteType.toLowerCase() : 'pr';
            switch (lowerCaseWasteType) {
                case 'pr':
                    return '#eb0000'
                case 'næ':
                    return '#0000eb';
                case 'sl':
                    return '#914e82';
                default:
                    return '#eb0000';
            }
        }

        function addRouteStopToMap(map, routeStop) {
            var circleOptions = {
                radius: getRadius(map._zoom),
                weight: 25,
                opacity: 0,
                fillOpacity: 0.8,
                fillColor: specifyFillColor(routeStop)
            };

            var labelOptions = {
                className: $scope.center.zoom < $scope.mapSettings.labelMinZoomLevel ? 'hidden' : '',
                direction: 'bottom',
                permanent: true
            };

            var stopCircleMarker = L.circle([routeStop.latitude, routeStop.longitude], circleOptions)
                .addTo(map)
                .on('click', routeStopClicked);

            if ($scope.mapSettings.labelEnabled) {
                stopCircleMarker.bindTooltip(routeStop.sequence.toString(), labelOptions);
            }

            if ($scope.mapSettings.popupEnabled) {
                stopCircleMarker.bindPopup(routeStop.address).on('popupopen', function (e) {
                    e.popup.setLatLng(e.popup._source.getLatLng());
                });
            }

            stopCircleMarker.isSelected = false;
            stopCircleMarker.routeLineId = routeStop.routeLineId;

            return stopCircleMarker;
        }

        function getStopDistanceFromMapCenter(routeStop) {
            if ($scope.position
                && $scope.position.lat && $scope.position.lng
                && routeStop.latitude && routeStop.longitude) {

                var stopCoords = { latitude: routeStop.latitude, longitude: routeStop.longitude },
                    currentPos = { latitude: $scope.position.lat, longitude: $scope.position.lng };

                return geolib.getDistance(currentPos, stopCoords);
            }

            return routeStop.distance;
        }

        function routeStopClicked(e) {
            drawStopCircles();

            var selectedStopCircleMarker = e.target;
            var selectionChangedEventParams = {};

            var unselected = _.filter($scope.stopCircleMarkers, function (stopCircleMarker) {
                return stopCircleMarker.isSelected && stopCircleMarker != selectedStopCircleMarker;
            });

            _.forEach(unselected, function (stopCircleMarker) {
                stopCircleMarker.isSelected = false;
                selectionChangedEventParams[stopCircleMarker.routeLineId] = false;
            });

            selectedStopCircleMarker.isSelected = !selectedStopCircleMarker.isSelected;

            selectionChangedEventParams[selectedStopCircleMarker.routeLineId] = selectedStopCircleMarker.isSelected;
            $rootScope.$broadcast('routeStopSelectionChanged', selectionChangedEventParams);
        }

        function routeStopCompleted(routeStop) {
            var routeStopCircle = $scope.stopCircleMarkers[routeStop.routeLineId];

            if (routeStopCircle && routeStopCircle.options.fillColor !== '#02ac4a') {
                routeStopCircle.setStyle({ fillColor: '#02ac4a' });
                L.DomUtil.removeClass(routeStopCircle._path, 'selected');
            }
        }

        function routeStopsCompleted(routeStops) {
            _.forEach(routeStops, function (reportedRouteStop) {
                routeStopCompleted(reportedRouteStop);
            });
        }

        function locationChanged(evt, position) {
            if ($scope.markers['currentPosition']) {
                $scope.markers['currentPosition'].icon = getIcon(false);

                if (position && position.coords && isPositionChanged(position.coords)) {
                    var newAngle = position.coords.heading || getAngleDiff(position.coords);

                    $scope.markers['currentPosition'].lat = Number(position.coords.latitude);
                    $scope.markers['currentPosition'].lng = Number(position.coords.longitude);
                    $scope.markers['currentPosition'].iconAngle = newAngle;

                    if ($scope.followMe) {
                        setViewToCurrentPosition(position.coords);
                    }
                }
            }
        }

        function deactivateMarker() {
            $scope.markers['currentPosition'].icon = getIcon(true);
        }

        function isPositionChanged(coords) {
            var previousPosition = $scope.markers['currentPosition'];
            return previousPosition.lat != coords.latitude || previousPosition.lng != coords.longitude;
        }

        function mapPositionChanged(e) {
            $scope.position = e.target.getCenter();

            drawStopCircles();
        }

        function getAngleDiff(coords) {
            var previousPosition = $scope.markers['currentPosition'];

            var dy = coords.longitude - previousPosition.lng;
            var dx = coords.latitude - previousPosition.lat;

            var theta = Math.atan2(dy, dx);
            theta *= 180 / Math.PI;
            if (theta < 0) theta = 360 + theta;

            return theta;
        }

        function getRadius(zoom) {
            var radiusArray = [
                500,
                500,
                500,
                500,
                500,    //5    
                2000,
                1000,
                500,
                250,
                175,     //10
                100,
                60,
                40,
                20,
                10,     //15
                5,
                2.5,
                1.25,
                1,
                0.5       //20
            ];



            var radius = radiusArray.length >= zoom ? radiusArray[zoom - 1] : 60;
            return $scope.mapSettings.useEsriLayer ? radius / 2 : radius;
        }

        function getSize(zoom) {
            var sizeArray = [
                10,
                10,
                10,
                10,    //5    
                15,
                15,
                15,
                15,
                15,    //10
                15,
                15,
                15,
                25,
                25,   //15
                25,
                25,
                25,
                25,
                25     //20
            ];



            var size = sizeArray.length >= zoom ? sizeArray[zoom - 1] : 60;
            return $scope.mapSettings.useEsriLayer ? size / 2 : size;
        }

        function getIcon(isInactive) {
            return {
                iconAnchor: [16, 16],
                iconSize: [32, 32],
                iconUrl: isInactive ? './images/icons/map-marker-grayscale.png' : './images/icons/map-marker.png'
            };
        }
    }
})();
