(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('settingsService', settingsService);

    settingsService.$inject = ['$q', '$http', 'cacheService', 'appVersion', 'appSettings'];

    function settingsService($q, $http, cacheService, appVersion, appSettings) {
        var cachePrefix = '_settings_';
        var userSettingsCacheKey = cachePrefix + 'usersetting';
        var vehicleSettingsCacheKey = cachePrefix + 'vehiclesetting';
        var activeDriverKey = 'activeDriver';

        var service = {
            getCallOrderCheckInterval: getCallOrderCheckInterval,
            getCategories: getCategories,
            getDeviations: getDeviations,
            getSettings: getSettings,
            getUserType: getUserType,
            getVehicleSettings: getVehicleSettings,
            getIsDataButtonsEnabled: getIsDataButtonsEnabled,
            getVoltAdjustUrl: getVoltAdjustUrl,
            getIsOrderTypeIconVisible: getIsOrderTypeIconVisible,
            getException1ButtonVisible: getException1ButtonVisible,
            getAutoModeToggleButtonVisible: getAutoModeToggleButtonVisible,
            getIsWeightUnitBotek: getIsWeightUnitBotek,
            getNoButtonRequiresDeviation: getNoButtonRequiresDeviation,
            getDriverList: getDriverList,
            setDriver: setDriver,
            getActiveDriver: getActiveDriver,
            getBTAdapterName: getBTAdapterName,
            getBTPrinterNames: getBTPrinterNames,
            clearActiveDriver: clearActiveDriver,
            getIsAjourUser: getIsAjourUser,
            getLoadProfileInfoData: getLoadProfileInfoData,
            getPrinterEnabled: getPrinterEnabled,
            getDisableAnimation: getDisableAnimation,
            getEnableWeight: getEnableWeight,
            getEnablePrefillOnDataButtonOne: getEnablePrefillOnDataButtonOne
        };

        return service;

        function getEnablePrefillOnDataButtonOne() {
            return getBooleanSettingValue('EnablePrefillOnDataButtonOne', true);
        }

        function getEnableWeight() {
            return getBooleanSettingValue('EnableWeight', false);
        }

        function getDisableAnimation() {
            return getBooleanSettingValue('DisableAnimation', false);
        }

        function getIsAjourUser() {
            return getBooleanSettingValue('IsAjour', false);
        }

        function getPrinterEnabled() {
            return getBooleanSettingValue('EnablePrinter', false);
        }

        function getLoadProfileInfoData() {
            return getBooleanSettingValue('LoadProfileInfoData', false);
        }

        function getIsOrderTypeIconVisible() {
            return getBooleanSettingValue('IsOrderTypeIconVisible', false);
        }

        function getAutoModeToggleButtonVisible() {
            return getBooleanSettingValue('AutoModeToggleButtonVisible', true);
        }

        function getException1ButtonVisible() {
            return getBooleanSettingValue('Exception1ButtonVisible', false);
        }

        function getIsDataButtonsEnabled() {
            return getBooleanSettingValue('IsDataButtonsEnabled', true);
        }

        function getNoButtonRequiresDeviation() {
            return getBooleanSettingValue('NoButtonRequiresDeviation', false);
        }

        function getIsWeightUnitBotek() {
            var deferred = $q.defer();

            getVehicleSettings().then(function (settings) {
                var weightUnit = _.find(settings, function (e) { return e.key === 'WeightUnit' });
                if (!weightUnit || weightUnit.value !== 'BOTEKU237D') {
                    deferred.resolve(false);
                } else {
                    deferred.resolve(true);
                }
            });

            return deferred.promise;
        }

        function getCategories() {
            var deferred = $q.defer();

            getSettings().then(function (configurations) {
                deferred.resolve(configurations.categories);
            });

            return deferred.promise;
        }

        function getDeviations() {
            var deferred = $q.defer();

            getSettings().then(function (configurations) {
                deferred.resolve(configurations.messages);
            });

            return deferred.promise;
        }

        function getDriverList() {
            var deferred = $q.defer();

            getSettings().then(function (userSettings) {
                if (userSettings && userSettings.driverList) {
                    deferred.resolve(userSettings.driverList);
                } else {
                    deferred.resolve([]);
                }
            });

            return deferred.promise;
        }

        function getSettings(refreshCache) {
            var deferred = $q.defer();

            if (!refreshCache) {
                cacheService.has(userSettingsCacheKey)
                    .then(function (exists) {
                        if (exists) {
                            return cacheService.get(userSettingsCacheKey);
                        } else {
                            return getServerSettings();
                        }
                    })
                    .then(function (settings) {
                        deferred.resolve(settings);
                    })
                    .catch(function () {
                        deferred.reject();
                    });
            } else {
                getServerSettings().then(function (settings) {
                    deferred.resolve(settings);
                },
                    function () {
                        deferred.reject();
                    });
            }

            return deferred.promise;
        }


        function hasTrueValue(setting) {
            return setting && setting.value && setting.value.toLowerCase() === "true";
        }

        function getUserType() {
            var deferred = $q.defer();

            getVehicleSettings().then(function (settings) {
                var undergroundUserInfo = _.find(settings, { key: 'IsUndergroundUser' });
                var serviceUserInfo = _.find(settings, { key: 'IsServiceUser' });
                var ajourUserInfo = _.find(settings, { key: 'IsAjour' });

                var userType = 'default';

                if (hasTrueValue(ajourUserInfo)) {
                    userType = 'ajour';
                } else if (hasTrueValue(undergroundUserInfo)) {
                    userType = (hasTrueValue(serviceUserInfo)) ? 'service' : 'underground';
                }

                deferred.resolve(userType);
            });

            return deferred.promise;
        }

        function getBTAdapterName() {
            var deferred = $q.defer();

            getVehicleSettings().then(function (settings) {
                var BTAdapterName = _.find(settings, { key: 'BTAdapterName' });
                var btAdapterNameValue = !BTAdapterName ? null : BTAdapterName.value;
                deferred.resolve(btAdapterNameValue);
            });

            return deferred.promise;
        }

        function getBTPrinterNames() {
            var deferred = $q.defer();

            getVehicleSettings().then(function (settings) {
                var BTPrinterName = _.find(settings, { key: 'BTPrinterName' });
                var names = null;
                if (BTPrinterName && BTPrinterName.value) {
                    var names = BTPrinterName.value.split(',');
                }
                deferred.resolve(names);
            });

            return deferred.promise;
        }

        function getVoltAdjustUrl() {
            var deferred = $q.defer();

            getVehicleSettings().then(settings => {
                var voltAdjustUrl = _.find(settings, { key: 'VoltAdjustUrl' });

                if (voltAdjustUrl && voltAdjustUrl.value) {
                    deferred.resolve(voltAdjustUrl.value);
                } else {
                    deferred.resolve(null);
                }
            },
                deferred.reject);

            return deferred.promise;
        }

        function getCallOrderCheckInterval() {
            var deferred = $q.defer();

            getVehicleSettings().then(settings => {
                var callOrderCheckInterval = _.find(settings, { key: 'CallOrderCheckInterval' });

                if (callOrderCheckInterval && callOrderCheckInterval.value) {
                    var callOrderCheckIntervalValue = Number(callOrderCheckInterval.value);
                    if (callOrderCheckIntervalValue !== 0) {
                        if (callOrderCheckIntervalValue > appSettings.callOrdersMinPollingIntervalMs) {
                            deferred.resolve(callOrderCheckIntervalValue);
                        } else {
                            deferred.resolve(appSettings.callOrdersMinPollingIntervalMs);
                        }
                    }
                    deferred.resolve(null);
                } else {
                    deferred.resolve(null);
                }
            },
                deferred.reject);

            return deferred.promise;
        }


        function getVehicleSettings(refreshCache) {
            var deferred = $q.defer();

            if (!refreshCache) {
                cacheService.has(vehicleSettingsCacheKey)
                    .then(function (exists) {
                        if (exists) {
                            return cacheService.get(vehicleSettingsCacheKey);
                        } else {
                            return getServerVehicleSettings();
                        }
                    })
                    .then(function (settings) {
                        deferred.resolve(settings);
                    })
                    .catch(function () {
                        deferred.reject();
                    });
            } else {
                getServerVehicleSettings().then(function (settings) {
                    deferred.resolve(settings);
                },
                    function () {
                        deferred.reject();
                    });
            }

            return deferred.promise;
        }

        function setDriver(driverId) {
            return new Promise(resolve => {
                getDriverName(driverId).then(driverName => {
                    return cacheService.set(activeDriverKey, { driverId: driverId, driverName: driverName });
                }).then(() => {
                    resolve(true);
                }).catch(() => {
                    resolve(false);
                });
            })
        }

        function getActiveDriver() {
            return cacheService.get(activeDriverKey);
        }

        function getDriverName(driverId) {
            var deferred = $q.defer();

            getSettings().then(function (userSettings) {
                if (userSettings && userSettings.driverList) {
                    let drivers = _.filter(userSettings.driverList, d => d.driverId === driverId);
                    let driverName = drivers && drivers.length > 0 && drivers[0].firstName !== undefined && drivers[0].lastName !== undefined
                        ? drivers[0].firstName + " " + drivers[0].lastName
                        : "";

                    deferred.resolve(driverName);
                }
                deferred.resolve("");
            });

            return deferred.promise;
        }

        function clearActiveDriver() {
            return cacheService.remove(activeDriverKey);
        }

        // private functions
        function getServerSettings() {
            var deferred = $q.defer();

            var settings;
            $http.post('/api/settings', { softwareVersion: appVersion })
                .then(function (response) {
                    settings = response.data;
                    return cacheService.set(userSettingsCacheKey, settings);
                })
                .then(function () {
                    deferred.resolve(settings);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getServerVehicleSettings() {
            var deferred = $q.defer();

            var settings;
            $http.get('/api/settings/vehicle')
                .then(function (response) {
                    settings = response.data;
                    return cacheService.set(vehicleSettingsCacheKey, settings);
                })
                .then(function () {
                    deferred.resolve(settings);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getBooleanSettingValue(keyName, defaultValue) {
            var deferred = $q.defer();
            getVehicleSettings().then(function (settings) {
                var settingValue = _.find(settings, function (e) { return e.key === keyName });
                if (!settingValue || !settingValue.value) {
                    deferred.resolve(defaultValue);
                } else {
                    if (settingValue.value.toLowerCase() === 'false') {
                        deferred.resolve(false);
                    } else {
                        deferred.resolve(true);
                    }
                }

            });

            return deferred.promise;
        }
    };
})();
