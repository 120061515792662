(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('ordersService', ordersService);

    ordersService.$inject = ['$q', '$http', '$rootScope', 'serviceUrls', 'cacheService', 'updateQueue', 'commonUtil', 'orderStatusTransitions', 'appSettings'];

    function ordersService($q, $http, $rootScope, serviceUrls, cacheService, updateQueue, commonUtil, orderStatusTransitions, appSettings) {

        var cachePrefix = '_orders_';

        var ordersService = {
            deleteOrder: deleteOrder,
            deleteOrderRequest: deleteOrderRequest,
            getExpiredOrders: getExpiredOrders,
            getOrder: getOrder,
            getOrders: getOrders,
            queueOrderDelete: queueOrderDelete,
            updateOrderStatus: updateOrderStatus,
            updateExpiredOrderStatus: updateExpiredOrderStatus,
            updateOrderStatusRequest: updateOrderStatusRequest
        };

        return ordersService;

        function deleteOrderRequest(orderId) {
			return deleteOrder(orderId);
		}

        function deleteOrder(orderId) {
            var deferred = $q.defer();

            cacheService.removeFrom(cachePrefix, null, 'orderId', orderId).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getExpiredOrders() {
            var deferred = $q.defer();

            cacheService.get(cachePrefix)
                .then(function (orders) {
                    var cleanOrderDataOlderThanInDays = _.find($rootScope.vehicleSettings, { key: 'CleanOrderDataOlderThanInDays' });
                    cleanOrderDataOlderThanInDays = cleanOrderDataOlderThanInDays && cleanOrderDataOlderThanInDays.value ?
                        parseInt(cleanOrderDataOlderThanInDays.value) :
                        appSettings.orders.defaultCleanOrderDataOlderThanInDays;

                    orders = _.filter(orders, function (order) {
                        return order.isRouteCached && moment(order.orderDate).add(cleanOrderDataOlderThanInDays, 'days') < moment();
                    });

                    deferred.resolve(orders);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getOrders(refreshCache) {
            var deferred = $q.defer();

            if (!refreshCache) {
                cacheService.has(cachePrefix)
                    .then(function (exists) {
                        if (exists) {
                            return cacheService.get(cachePrefix);
                        } else {
                            return getServerOrders();
                        }
                    })
                    .then(function (orders) {
                        deferred.resolve(orders);
                    })
                    .catch(function () {
                        deferred.reject();
                    });
            } else {
                getServerOrders().then(function (orders) {
                    deferred.resolve(orders);
                }, function () {
                    cacheService.has(cachePrefix)
                        .then(function (exists) {
                            if (exists) {
                                return cacheService.get(cachePrefix);
                            } else {
                                deferred.reject();
                            }
                        })
                        .then(function (orders) {
                            orders = _.filter(orders, { 'isRouteCached': true });

                            if (orders && orders.length)
                                deferred.resolve(orders);
                            else
                                deferred.reject();
                        })
                        .catch(function () {
                            deferred.reject();
                        });
                });
            }

            return deferred.promise;
        }

        function getOrder(orderId) {
            var deferred = $q.defer();

            getOrders().then(function (orders) {
                var order = _.find(orders, { 'orderId': Number(orderId) });
                deferred.resolve(order);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
        
        function queueOrderDelete(orderId) {
            var deferred = $q.defer();

            var uploadAction = {
                id: commonUtil.generateGuid(),
                parameters: [orderId],
                type: 'deleteOrder'
            };

            updateQueue.addUpdateAction(uploadAction)
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function updateOrderStatus(orderId, transitionId) {
            return updateOrderStatusImpl(orderId, transitionId);
        }

        function updateExpiredOrderStatus(orderId, transitionId, updateDate) {
            return updateOrderStatusImpl(orderId, transitionId, updateDate);
        }

        function createUpdateOrderDto(order, updateDate) {
            return {
                orderId: order.orderId,
                orderType: order.orderType,
                updateDate: updateDate
            }
        }

        function updateOrderStatusImpl(orderId, transitionId, updateDate) {
            var deferred = $q.defer();

            var cachedOrder;
            cacheService.get(cachePrefix)
                .then(function (cachedOrders) {
                    cachedOrder = _.find(cachedOrders, { 'orderId': Number(orderId) });
                    if (cachedOrder) {
                        cachedOrder.transitionId = transitionId;
                        if (transitionId === orderStatusTransitions.start) {
                            cachedOrder.isRouteCached = true;
                        }
                    }

                    return cacheService.set(cachePrefix, cachedOrders);
                })
                .then(function () {
                    //handle expired orders, send the latest route stop completion date
                    if (cachedOrder) {
                        if (updateDate) {
                            var updateAction = {
                                id: commonUtil.generateGuid(),
                                parameters: [orderId, transitionId, createUpdateOrderDto(cachedOrder, updateDate)],
                                type: 'updateOrderStatus'
                            };

                            return updateQueue.addUpdateAction(updateAction);
                        }
                        else if (moment(cachedOrder.orderDate) >= moment().startOf('day')) {

                            var updateAction = {
                                id: commonUtil.generateGuid(),
                                parameters: [orderId, transitionId, createUpdateOrderDto(cachedOrder, updateDate)],
                                type: 'updateOrderStatus'
                            };

                            return updateQueue.addUpdateAction(updateAction);
                        }
                    }
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function updateOrderStatusRequest(orderId, transitionId, order) {
            var deferred = $q.defer();

            $http.put(serviceUrls.orders + '/' + orderId + '/status/' + transitionId, order)
                .then(function () {
                    if (transitionId === orderStatusTransitions.complete)
                        return cacheService.removeFrom(cachePrefix, null, 'orderId', Number(orderId));
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        // private functions
        function getServerOrders() {
            var deferred = $q.defer();

            var orders;

            cacheService.get(cachePrefix)
                .then(function (cachedOrders) {
                    orders = _.filter(cachedOrders, { 'isRouteCached': true });
                    return $http.get(serviceUrls.orders);
                })
                .then(function (response) {
                    var serverOrders = response.data;

                    _.forEach(serverOrders, function (order, index) {
                        var isRouteCached = _.some(orders, { 'orderId': order.orderId });
                        if (!isRouteCached) {
                            var orderDate = new Date(order.orderDate);
                            order.date = orderDate.getDate().toString().padStart(2, '0') + '/' + (orderDate.getMonth() + 1);
                            order.isRouteCached = false;
                            order.transitionId = null;

                            orders.push(order);
                        }
                    });

                    return cacheService.set(cachePrefix, orders);
                })
                .then(function () {
                    deferred.resolve(orders);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();
