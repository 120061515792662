(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('RouteStop', RouteStopFactory);

    RouteStopFactory.$inject = ['$log', '$rootScope'];

    function RouteStopFactory($log, $rootScope) {
        function RouteStop(routeStopData, currentPosition, dataButtons) {
            var self = this;

            try {
                //Public inteface
                self.updateDistance = function (position) {
                    if (position) {
                        var stopCoords = { latitude: self.latitude, longitude: self.longitude },
                            currentPos = { latitude: position.coords.latitude, longitude: position.coords.longitude };

                        self.distance = geolib.getDistance(currentPos, stopCoords);
                        self.distanceText = self.getDistanceText();
                    }
                };

                self.getDistanceText = function () {
                    if (self.distance || self.distance === 0) {
                        if (self.distance >= 1000) {
                            return (self.distance / 1000).toFixed(2).replace('.', ',') + ' km';
                        } else {
                            return self.distance + ' m';
                        }
                    } else {
                        return 'N/A';
                    }
                };

                self.getDirectionLink = function (currentPosition) {
                    if (currentPosition && self.latitude && self.longitude) {
                        return 'https://www.google.com/maps/dir/?api=1&origin=' +
                            currentPosition.coords.latitude +
                            ',' +
                            currentPosition.coords.longitude +
                            '&destination=' +
                            self.latitude +
                            ',' +
                            self.longitude +
                            '&travelmode=driving';
                    }

                    return '';
                };

                self.isHandled = function () {
                    return self.status !== 'uncompleted';
                };

                self.hasFillValue = function () {
                    return _.some(self.units, function (unit) { return unit.fill; });
                };

                self.getMaxFillValue = function () {
                    return _.max(_.map(self.units,
                        function (unit) {
                            return _.toNumber(_.replace(unit.fill, '%', '')) || 0;
                        })) ||
                        0;
                };

                self.getMessageParts = function () {
                    var messageOrderData = _.find(self.orderData, function (orderDataItem) {
                        return _.includes(orderDataItem.description, 'DetailData_Message');
                    });

                    return messageOrderData ? messageOrderData.value.split(';') : null;
                };

                self.getMessageNumber = function () {
                    var messageParts = self.getMessageParts();
                    var messageNumber = messageParts && messageParts.length > 1 ? messageParts[1] : null;

                    if (messageNumber && isNaN(messageNumber)) {
                        messageNumber = '-';
                    }

                    return messageNumber;
                };

                self.getMessageText = function () {
                    var messageParts = self.getMessageParts();
                    return messageParts && messageParts.length ? messageParts[0] : null;
                };

                self.assignLogData = function (currentPosition) {
                    if (!self.manuallySetPosition) {
                        self.addOrUpdateLogData('DegLat', currentPosition.coords.latitude);
                        self.addOrUpdateLogData('DegLon', currentPosition.coords.longitude);
                    }
                };

                self.addOrUpdateLogData = function (name, value) {
                    var logDataElement = _.find(self.logData, { 'name': name });

                    if (logDataElement)
                        logDataElement.value = value;
                    else
                        self.logData.push({ 'name': name, 'value': value });
                };

                self.sameDate = function (other) {
                    var orderData1 = self.orderData;
                    var orderData2 = other.orderData;
                    if (orderData1 && orderData2) {
                        var date1 = _.find(orderData1, { key: "DetailData_OrderDate" });
                        var date2 = _.find(orderData2, { key: "DetailData_OrderDate" });
                        return date1 && date2 && date1.value === date2.value;
                    }
                    return !orderData1 && !orderData2;
                }

                self.sameType = function (other) {
                    var units1 = self.units;
                    var units2 = other.units;
                    if (units1 && units2) {
                        var types1 = _.map(units1, u => u.orderItemType.toString()).join("");
                        var types2 = _.map(units2, u => u.orderItemType.toString()).join("");
                        return types1 === types2;
                    }
                    return !units1 && !units2;
                }

                if (routeStopData) {
                    this.agreementLines = routeStopData.agreementLines;
                    this.comments = routeStopData.comments || [];
                    this.description = routeStopData.description || '';
                    this.deviations = routeStopData.deviations || [];
                    this.deviationPictures = routeStopData.deviationPictures || [];
                    this.distanceText = self.getDistanceText();
                    this.isAttended = routeStopData.isAttended || false;
                    this.grade = routeStopData.grade;
                    this.logData = routeStopData.logData || [];
                    this.logType = routeStopData.logType;
                    this.message = '';
                    this.orderData = routeStopData.orderData || {};
                    this.routeLineId = routeStopData.routeLineId;
                    this.sequence = getSequence(routeStopData)
                    this.status = routeStopData.status || 'uncompleted';
                    this.successful = routeStopData.successful || 'unsuccessful';
                    this.system = routeStopData.paSystem;
                    this.units = routeStopData.units;
                    this.wasClose = routeStopData.wasClose || false;
                    this.wasteType = routeStopData.pointType;
                    if (this.units && this.units.length > 0) {
                        this.address = routeStopData.units[0].address;
                    }

                    for (var i = 0; i < this.units.length; ++i) {
                        this.units[i].fillText = this.units[i].fillText || '';
                        this.units[i].fillValue = getFillValue(this.units[i].fill);
                        this.units[i].message = this.units[i].message || '';
                        this.units[i].orderDetailText = formatOrderDetailText(this.units[i].orderDetailText);
                        this.units[i].voltText = formatVolt(this.units[i].volt);
                    }
                    this.isAutoComplete = "False";
                    this.latitude = routeStopData.degLat;
                    this.longitude = routeStopData.degLong;

                    //If current position is provided, calculate the distance
                    if (currentPosition) {
                        self.updateDistance(currentPosition);
                    }

                    if (dataButtons) {
                        this.dataButtons = [];
                        for (var j = 0; j < dataButtons.length; j++) {
                            setDefaultValueNOifNull(dataButtons[j]);
                            if (dataButtonHasNameAndIdentity(dataButtons[j])) {
                                if (!this.dataButtons.includes(dataButtons[j])) {
                                    this.dataButtons.push(angular.copy(dataButtons[j]));
                                }
                            }
                        }
                    }

                }

            } catch (error) {
                $log.error("error occured parsing routestops: " + error);
                throw "ParsingError";
            }
        }

        function setDefaultValueNOifNull(dataButton) {
            if (!dataButton.valueNO) {
                dataButton.valueNO = dataButton.valueENG ? dataButton.valueENG : dataButton.parameter;
            }
        }

        function dataButtonHasNameAndIdentity(dataButton) {
            if (!dataButton.valueNO) {
                return false;
            }

            return dataButton.identity !== '' &&
                dataButton.valueNO.indexOf('Data ') !== 0 &&
                dataButton.valueNO.indexOf('-') !== 0;
        }

        //Private functions
        function getSequence(routeStopData) {
            if (!routeStopData.sequence && $rootScope.userType === 'ajour') {
                return getAjourSequence(routeStopData);
            }

            return routeStopData.sequence;
        }

        function getAjourSequence(routeStopData) {
            if (routeStopData && routeStopData.orderData && routeStopData.orderData.length > 0) {
                let ajourSequenceDetail = routeStopData.orderData.find(d => d.key === 'DetailData_Sequence');
                if (ajourSequenceDetail) {
                    var parsedSequence = parseInt(ajourSequenceDetail.value);
                    if (isNaN(parsedSequence)) {
                        return null;
                    }

                    return parsedSequence;
                }
            }

            return null;
        }

        function getFillValue(fill) {
            var fillValue = parseFloat(fill ? fill.replace('%', '') : 0);

            fillValue = !isNaN(fillValue) ? fillValue : 0;
            fillValue = fillValue <= 100 ? fillValue : 100;

            return fillValue;
        }

        function formatOrderDetailText(orderDetailText) {
            var formattedOrderDetailText = orderDetailText;

            if (formattedOrderDetailText) {
                formattedOrderDetailText = _.replace(formattedOrderDetailText, 'OrderDetailTypeInfo_#', '');
            }

            return formattedOrderDetailText;
        }

        function formatVolt(volt) {
            if (!volt)
                return '0';

            var formattedVolt = volt;
            if (volt.length > 2 && volt.indexOf(',') === -1) { //correct former bug where voltage comes as string "134" -> 13,4V
                formattedVolt = volt.substr(0, 2) + ',' + volt.substr(2);
            } else if (volt.length > 2 && volt.indexOf(',') !== -1) {
                formattedVolt = volt.substr(0, 2) + ',' + volt.substr(3, 2);
            } else if (volt.length > 2 && volt.indexOf('.') !== -1) {
                formattedVolt = volt.substr(0, 2) + ',' + volt.substr(3, 2);
            }

            if (formattedVolt.length > 0) {
                formattedVolt += 'V';
            }

            return formattedVolt;
        }

        return RouteStop;
    }
})();
