(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('routeStopUpdateChecker', routeStopUpdateChecker);

    routeStopUpdateChecker.$inject = ['$q', '$rootScope', '$http', 'serviceUrls', 'cacheService', 'routeStopsService', 'commonUtil', 'updateQueue',
        'imageService', 'deviationService', 'ordersService', 'callOrdersService', 'orderStatusTransitions'];

    function routeStopUpdateChecker($q, $rootScope, $http, serviceUrls, cacheService, routeStopsService, commonUtil, updateQueue, imageService,
        deviationService, ordersService, callOrdersService, orderStatusTransitions) {

        var cachePrefix = '_routeStops_';
        var routeLocks = {};
        var routeScheduleCallbacks = {};

        var routeStopUpdateChecker = {
            scheduleUpdates: scheduleUpdates,
            uploadRouteStop: uploadRouteStop
        };

        return routeStopUpdateChecker;

        function scheduleUpdates(orderId) {
            var deferred = $q.defer();

            if (orderId) {
                var cacheKey = cachePrefix + orderId;

                cacheService.get(cacheKey)
                    .then(function (route) {
                        return scheduleRouteUpdates(route, orderId);
                    })
                    .finally(function () {
                        deferred.resolve();
                    });

            } else {
                cacheService.getByPrefix(cachePrefix)
                    .then(function (routes) {
                        var requests = _.map(routes, function (route, orderId) {
                            return scheduleRouteUpdates(route, orderId);
                        });

                        return $q.all(requests);
                    })
                    .finally(function () {
                        deferred.resolve();
                    });
            }

            return deferred.promise;
        }

        function scheduleRouteUpdates(route, orderId) {
            var deferred = $q.defer();

            var completedRouteStops = _.orderBy(_.filter(route.routeStops, { status: 'completed' }), ['time'], ['asc']);

            if (completedRouteStops.length > 0) {
                if (!routeLocks[orderId]) {
                    routeLocks[orderId] = true;

                    var notUncompletedRouteStops = _.reject(route.routeStops, { status: 'uncompleted' });
                    var handledCompletedRouteStops = _.reject(notUncompletedRouteStops, { status: 'completed' });

                    var requests = [
                        ordersService.getOrder(orderId),
                        callOrdersService.getCallOrder(orderId)
                    ];

                    $q.all(requests).then(function (result) {
                        var order = result[0] || result[1];

                        //Upload every entry when the number of completed routeStops reaches the next interval bracket
                        //E.g. allowedQueueSize is 5, once 5 stops are marked completed the first 4 get uploaded
                        //Once 10 stops are completed the stops from number5 to number9 get uploaded, and so on
                        var allowedQueueSize = (order && order.orderType === 6) ? 1 : $rootScope.userSettings.interval;
                        //UploadBarrier is how many routeStops should be processed total, some of it has already been done
                        var uploadBarrier = notUncompletedRouteStops.length - (notUncompletedRouteStops.length % allowedQueueSize) - 1;

                        //When last routestop is done, or when user is quitting from the order screen all the stops need to be uploaded
                        var flush = order.transitionId === orderStatusTransitions.cancel || route.routeStops.length === notUncompletedRouteStops.length;
                        //Upload as many completed stops as is needed to reach the uploadBarrier, or all in case of flush                        
                        var uploadCount = flush ? completedRouteStops.length : (uploadBarrier - handledCompletedRouteStops.length);

                        if (uploadCount > 0) {
                            var routeStopsToUpload = _.slice(completedRouteStops, 0, uploadCount);

                            var requests = _.map(routeStopsToUpload, function (routeStopToUpload) {
                                return uploadRouteStop(orderId, routeStopToUpload);
                            });

                            var saves = _.map(routeStopsToUpload, function (routeStopToUpload) {
                                return routeStopMarkedForUpload(orderId, routeStopToUpload);
                            });

                            $q.all(requests)
                                .then(function (data) {
                                    return $q.all(saves);
                                })
                                .finally(function () {
                                    routeLocks[orderId] = false;
                                    callRouteScheduleCallbacks(orderId);
                                    deferred.resolve();
                                });

                        } else {
                            routeLocks[orderId] = false;
                            callRouteScheduleCallbacks(orderId);
                            deferred.resolve();
                        }
                    });
                } else {
                    routeScheduleCallbacks[orderId] = routeScheduleCallbacks[orderId] || [];
                    routeScheduleCallbacks[orderId].unshift(function () {
                        deferred.resolve();
                    });
                }
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }

        function uploadRouteStop(orderId, routeStop) {
            var deferred = $q.defer();

            deviationService.getImagesFromCache(orderId, routeStop.routeLineId)
                .then(function (images) {
                    if (images) {

                        var addUpdates = _.map(images, function (image) {
                            var uploadAction = {
                                id: commonUtil.generateGuid(),
                                parameters: [orderId, routeStop.routeLineId, image.agreementLineIds, image.imageHash],
                                type: 'uploadImage'
                            };

                            return updateQueue.addUpdateAction(uploadAction);
                        });

                        return $q.all(addUpdates);
                    }
                })
                .then(function () {
                    var updateAction = {
                        id: commonUtil.generateGuid(),
                        parameters: [orderId, routeStop.routeLineId],
                        type: 'updateRouteStop',
                        isPostponable: true
                    };

                    return updateQueue.addUpdateAction(updateAction);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });
            
            return deferred.promise;
        }

        function routeStopMarkedForUpload(orderId, routeStopToUpload) {
            var deferred = $q.defer();

            routeStopToUpload.status = 'markedForUpload';
            routeStopsService.saveLocalRouteStop(orderId, routeStopToUpload).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function callRouteScheduleCallbacks(orderId) {
            routeScheduleCallbacks[orderId] = routeScheduleCallbacks[orderId] || [];
            var routeScheduleCallback = routeScheduleCallbacks[orderId].pop();

            if (routeScheduleCallback) {
                routeScheduleCallback();
                callRouteScheduleCallbacks(orderId);
            }
        }
    }
})();
