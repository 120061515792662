(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('errorReportService', errorReportService);

    errorReportService.$inject = ['$q', '$log', '$http', '$localForage', '$rootScope', 'serviceUrls', 'cacheService', 'appSettings', 'logService'];

    function errorReportService($q, $log, $http, $localForage, $rootScope, serviceUrls, cacheService, appSettings, logService) {
        var connection;
        var isListening = true;

        var errorReportService = {
            sendErrorReport: sendErrorReport,
            startListen: startListen,
            stopListen: stopListen
    };

        return errorReportService;

        function sendErrorReport(message, isSystem) {
            var deferred = $q.defer();

            cacheService.getAllKeysWithoutUserPrefix().then(function (items) {
                var errorReportLines = _.map(items, function (item) {
                    return {
                        content: JSON.stringify(item.value.data),
                        key: item.key,
                        type: 'cache'
                    };
                });

                var errorReport = {
                    content: message,
                    errorReportLines: errorReportLines,
                    isSystem: isSystem
                };

                return $http.post(serviceUrls.errorReport, errorReport);
            })
            .then(function (resp) {
                if (resp && resp.data && resp.data.status === 200){
                    setTimeout(()=>logService.deleteLogs(), 10);
                }
                deferred.resolve();
            })
            .catch(function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function startListen() {
            disconnect();
            connect();
            setTimeout(() => {
                tryReconnect();
            }, appSettings.errorReportSocketCheckInterval);
        }

        function tryReconnect() {
            if (connection && connection.connection) {
               if (connection.connection.connectionState === 2) {
                    //Disconnected
                    disconnect();
                    connection = null;
                }
            }
            if ($rootScope.isOnline) {
                if (!connection) {
                    connect();
                }
            }
          
            setTimeout(() => {
                tryReconnect();
            }, appSettings.errorReportSocketCheckInterval);
            
            
        }

        function connect() {
            $localForage.getItem('authData').then(function (authData) {
                if (authData) {
                    var options = {
                        logging: signalR.LogLevel.Information,
                        accessTokenFactory: function () {
                            return authData.token;
                        }
                    };

                    connection = new signalR.HubConnectionBuilder()
                        .withUrl('errorreporthub?customerid=' + authData.customerId, options)
                        .build();

                    connection.on("RequestErrorReport", function (data) {
                        sendErrorReport('DTA_ADMIN: ' + data, false)
                            .then(result => {
                                $log.info('error report requested by admin sent: ' + data);
                        })
                        .catch(err => {
                            $log.error('error report requested by admin failed');
                        });;
                    });

                    connection.start().catch(function (error) {
                        $log.warn('errorReport connection failed');
                    });
                }
            });
        }

        function disconnect() {
            if (connection) {
                connection.stop();
            }
        }

        function stopListen() {
            isListening = false;
            if (connection) {
                connection.stop();
            }
        }
    }
})();
