(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('completedRouteStopCard', completedRouteStopCard);

    completedRouteStopCard.$inject = ['$rootScope', 'settingsService'];

    function completedRouteStopCard($rootScope, settingsService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/completed-route-stop-card.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope) {

            settingsService.getException1ButtonVisible().then(function (exception1ButtonVisible) {
                scope.isDeviation1ButtonVisible = exception1ButtonVisible;
            });

            scope.isAjour = $rootScope.userType === 'ajour';
        }
    }
})();
