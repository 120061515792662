(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('expirationService', expirationService);

    expirationService.$inject = ['$q', '$log', 'ordersService', 'routeStopsService', 'routeStopUpdateChecker', 'orderStatusTransitions', 'deviationService', 'cacheService'];

    function expirationService($q, $log, ordersService, routeStopsService, routeStopUpdateChecker, orderStatusTransitions, deviationService, cacheService) {
        var expirationService = {
            deleteExpiredOrders: deleteExpiredOrders,
            deleteOrphanedReportedRouteStops: deleteOrphanedReportedRouteStops
        };

        return expirationService;

        function deleteOrphanedReportedRouteStops(orders) {

            var def = $q.defer();

            if (orders && orders.length > 0) {
                var currentOrderIds = _.map(orders, o => o.orderId);
                cacheService.getByPrefix('_reportedRouteStops_').then((orderIds) => {
                    if (orderIds) {
                        for (var reportedRouteStopsOrderId in orderIds) {
                            if (orderIds.hasOwnProperty(reportedRouteStopsOrderId)) {
                                if (_.findIndex(currentOrderIds, reportedRouteStopsOrderId) === -1) {
                                    //reported routeStops do not belong to the current orders
                                    cacheService.remove('_reportedRouteStops_' + reportedRouteStopsOrderId);
                                }
                            }
                        }
                    }
                    def.resolve();
                });
            } else {
                def.resolve();
            }
            return def;
        }

        function uploadCompletedRouteStopsForExpiredOrder(expiredOrder) {
            var deferred = $q.defer();

            var orderId = expiredOrder.orderId;
            cacheService.get('_routeStops_' + orderId)
                .then((data) => {
                    if (data && data.routeStops && data.routeStops.length > 0) {
                        var completedRouteStops = _.filter(data.routeStops, (rs) => rs.status === 'completed');
                        _.forEach(completedRouteStops, function (rs) {
                            $log.info('Expired order route stop added to update queue');
                            routeStopUpdateChecker.uploadRouteStop(orderId, rs).then(function () {
                                rs.status = 'markedForUpload';
                                routeStopsService.saveLocalRouteStop(orderId, rs);
                            });
                        });

                        var areAllRouteStopsReady = _.every(data.routeStops, function (routeStop) {
                            return isRouteStopReady(routeStop);
                        });

                        var hasUncompleted = _.some(data.routeStops, (rs) => rs.status === 'uncompleted');

                        if (areAllRouteStopsReady) {
                            var uploadedRouteStops = _.filter(data.routeStops, (rs) => rs.status === 'uploaded');
                            var latestRouteStopCompleted = _.sortBy(uploadedRouteStops, 'time')[0];
                            if (latestRouteStopCompleted && latestRouteStopCompleted.time) {
                                if (hasUncompleted) {
                                    ordersService.updateExpiredOrderStatus(orderId, orderStatusTransitions.cancel, latestRouteStopCompleted.time);
                                } else {
                                    ordersService.updateExpiredOrderStatus(orderId, orderStatusTransitions.complete, latestRouteStopCompleted.time);
                                }
                            }
                        }
                    }
                    deferred.resolve();
                });
            return deferred.promise;
        }

        function isRouteStopReady(routeStop) {
            return routeStop.status !== 'completed' && routeStop.status !== 'markedForUpload';
        }

        function deleteExpiredOrders() {
            var deferred = $q.defer();

            var expiredOrderIds;
            var deleteRouteResults = null;
            ordersService.getExpiredOrders()
                .then(function (expiredOrders) {
                    expiredOrderIds = _.map(expiredOrders, 'orderId');

                    var uploadCompletedRequests = _.map(expiredOrders, function (expiredOrder) {
                        return uploadCompletedRouteStopsForExpiredOrder(expiredOrder);
                    });

                    return $q.all(uploadCompletedRequests);
                }).then(function () {
                    //delete expired routes if all route stops are uploaded or not started
                    var deleteExpiredRoutesRequests = _.map(expiredOrderIds, function (expiredOrderId) {
                        return routeStopsService.deleteLocalRoute(expiredOrderId, function (route) {
                            return !route || !route.routeStops || _.every(route.routeStops, function (routeStop) {
                                return isRouteStopReady(routeStop);
                            });
                        });
                    });
                    return $q.all(deleteExpiredRoutesRequests);
                }).then(function (deleteResults) {
                    //if routes were deleted successfully delete order
                    deleteRouteResults = deleteResults;
                    var deleteOrderRequests = _.map(_.filter(expiredOrderIds, function (expiredOrderId, index) {
                        return deleteRouteResults[index];
                    }), function (deletedRouteOrderId) {
                        return [ordersService.deleteOrder(deletedRouteOrderId), deviationService.removeImageFromCache(deletedRouteOrderId)];
                    });

                    deleteOrderRequests = _.flatten(deleteOrderRequests);
                    return $q.all(deleteOrderRequests);
                }).then(function () {
                    //if routes were deleted successfully reported route stops
                    var deleteReportedRouteStops = _.map(_.filter(expiredOrderIds, function (expiredOrderId, index) {
                        return deleteRouteResults[index];
                    }), function (expiredOrderId) {
                        return cacheService.remove('_reportedRouteStops_' + expiredOrderId);
                    });
                    return $q.all(deleteReportedRouteStops);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();
