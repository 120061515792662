(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('NavbarController', navbarController);

    navbarController.$inject = ['$state', '$rootScope', '$scope', 'authService', 'ordersService', 'orderStatusTransitions', '$kWindow', '$window',
        'routeStopsService', 'settingsService', 'orderDetailTypesService', 'cacheService', 'errorReportService', 'profileInfoService'];

    function navbarController($state, $rootScope, $scope, authService, ordersService, orderStatusTransitions, $kWindow, $window,
        routeStopsService, settingsService, orderDetailTypesService, cacheService, errorReportService, profileInfoService) {

        $scope.routeName = '';
        $scope.prevStateName = '';
        $scope.prevParams = {};
        $scope.state = '';

        $scope.showOrderModal = false;
        $scope.showInrouteModal = false;

        $scope.back = back;
        $scope.openMenu = openMenu;
        $scope.openOrderDetailTypesFilter = openOrderDetailTypesFilter;
        $scope.filterVisible = false;
        $scope.orderDetailTypesFilterActive = false;
        $scope.orderDetailTypesFilterVisible = false;
        $scope.logout = logout;
        $scope.cancelOrder = cancelOrder;
        $scope.toggleCompleted = toggleCompleted;
        $scope.support = support;
        $scope.settings = settings;
        $scope.showNearbyRouteStops = showNearbyRouteStops;
        $scope.callOrders = callOrders;
        $scope.messages = messages;
        $scope.isAjour = $rootScope.userType === 'ajour';
        $scope.orderDetailTypesFilterDisabled = true;

        settingsService.getCallOrderCheckInterval().then(callOrderCheckInterval => $rootScope.callOrderCheckInterval = callOrderCheckInterval);

        var routeStopsCachePrefix = '_routeStops_';
        var isOrderDetailTypeWndOpen = false;
        var orderDetailWindowInstance;
        var callOrderWindowInstance;
        var messageWindowInstance;
        var addedNearbyRouteStops = [];

        angular.element($window).bind('orientationchange', function () {
            if (orderDetailWindowInstance) {
                orderDetailWindowInstance.close(false);
            }

            if (messageWindowInstance) {
                messageWindowInstance.close(false);
            }

            if (callOrderWindowInstance) {
                callOrderWindowInstance.close(false);
            }

            $rootScope.$broadcast('closeDeviationWindow');

            $rootScope.$broadcast('closeNearbyRouteStopWindow');

        });

        initController();

        function initController() {

            $scope.$on('routeLoaded', function (eventName, routeName) {
                $scope.routeName = routeName;
            });

            $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                $scope.routeName = '';

                if ((fromState.name === 'main.routeStops' || fromState.name === 'main.completedRouteStops') && (toState.name === 'main.map' || toState.name === 'main.deviation')) {
                    $scope.prevStateName = fromState.name;
                    $scope.prevParams = fromParams;
                }

                $scope.state = $state.current.name;
                $scope.isOrdersView = $scope.state === 'main.orders';

                $scope.filterVisible = $scope.state === 'main.routeStops' || $scope.state === 'main.completedRouteStops';

                settingsService.getUserType().then(function (userType) {
                    $scope.orderDetailTypesFilterVisible = $scope.filterVisible && userType === 'service';
                });

                settingsService.getIsDataButtonsEnabled().then(function (isDataButtonsEnabled) {
                    $rootScope.isDataButtonsEnabled = isDataButtonsEnabled;
                });
            });

            $scope.$on('orderDetailTypesFilterChanged', function (eventName, filteredOrderDetailTypes) {
                $scope.orderDetailTypesFilterActive = filteredOrderDetailTypes && filteredOrderDetailTypes.length;
            });

            $scope.$on('routeStopsLoadCompleted',
                function (eventName) {
                    $scope.orderDetailTypesFilterDisabled = false;
                });

            orderDetailTypesService.getSelectedOrderDetailTypes().then(function (selectedOrderDetailTypes) {
                $scope.orderDetailTypesFilterActive = selectedOrderDetailTypes && selectedOrderDetailTypes.length;
            });

            
            $scope.$on('orderDetailTypesCountFinished',
                function () {
                    if (!isOrderDetailTypeWndOpen) {
                        openOrderDetailTypesFilter();
                    }
                });
        };

        function openMenu() {
            if ($state.current.name === 'main.orders') {
                $scope.showOrderModal = true;
            } else {
                $scope.showInrouteModal = true;
            }
        }

        function openOrderDetailTypesFilter() {
            if (!$scope.orderDetailTypesFilterDisabled) {
                isOrderDetailTypeWndOpen = true;
                orderDetailWindowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        title: 'Velg ordretyper',
                        draggable: false,
                        movable: false,
                        resizable: false,
                        visible: false,
                        height: '100%',
                        width: 400
                    },
                    templateUrl: 'app/orderDetailTypesFilter/order-detail-types-filter-view.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'OrderDetailTypesFilterController'
                });
            }
        }

        function cancelOrder() {
            if (cancelOrderConfirmation()) {
                if (!$state.params.callOrder) {
                    ordersService.updateOrderStatus($state.params.orderId, orderStatusTransitions.cancel).then(function () {
                        cancelOrderConfirmed();
                    }, function () {
                        // error occurred
                    });
                } else {
                    cancelOrderConfirmed();
                }
            }
        }

        function toggleCompleted() {
            if ($state && $state.params.orderId) {

                if ($state.current.name === 'main.completedRouteStops') {
                    $state.go('main.routeStops', {
                        orderId: $state.params.orderId,
                        sortBy: routeStopsService.getSortBy(),
                        splitView: routeStopsService.getSplitViewStatus(),
                        status: 'uncompleted'
                    });
                } else {
                    $state.go('main.completedRouteStops', { orderId: $state.params.orderId });
                }

                hideModal();
            }
        }

        function logout() {
            if ($rootScope.isOnline) {
                errorReportService.stopListen();
                profileInfoService.deleteProfileInfoCache(); 
                settingsService.clearActiveDriver();
                authService.logout().then(function () {
                    $rootScope.callOrderCheckInterval = null;
                    $rootScope.orderDeleteMode = false;
                    $state.go('login');

                    hideModal();

                    $scope.$on('$stateChangeSuccess', function () {
                        $scope.routeName = '';
                    });
                });
            } else {
                alert('Cannot log out offline!');
            }
        }

        function back() {
            if ($state.current.name === 'main.routeStops' || ($state.current.name === 'main.map' && $scope.prevStateName === 'main.routeStops')) {
                if (cancelOrderConfirmation()) {
                    $rootScope.$broadcast('showBusyIndicator');

                    ordersService.updateOrderStatus($state.params.orderId, orderStatusTransitions.cancel).then(function () {
                        $state.go('main.orders');
                    }, function () {
                        // error occurred
                    });
                }
            } else if ($state.current.name === 'main.map') {
                $rootScope.$broadcast('showBusyIndicator');

                if ($scope.prevStateName) {
                    if ($scope.prevStateName === 'main.completedRouteStops') {
                        $state.go($scope.prevStateName, { orderId: $scope.prevParams.orderId });
                    } else {
                        $state.go($scope.prevStateName,
                            {
                                orderId: $scope.prevParams.orderId,
                                sortBy: routeStopsService.getSortBy(),
                                splitView: routeStopsService.getSplitViewStatus(),
                                status: $scope.prevParams.status
                            });
                    }
                } else {
                    $state.go('main.orders');
                }
            } else if ($state.current.name === 'main.deviation') {
                $rootScope.$broadcast('showBusyIndicator');

                if ($scope.prevStateName) {
                    if ($scope.prevStateName === 'main.completedRouteStops') {
                        $state.go($scope.prevStateName, { orderId: $scope.prevParams.orderId });
                    } else {
                        $state.go($scope.prevStateName,
                            {
                                orderId: $scope.prevParams.orderId,
                                sortBy: routeStopsService.getSortBy(),
                                splitView: routeStopsService.getSplitViewStatus(),
                                status: $scope.prevParams.status
                            });
                    }
                } else {
                    $state.go('main.routeStops',
                        {
                            orderId: $state.params.orderId,
                            sortBy: routeStopsService.getSortBy(),
                            splitView: routeStopsService.getSplitViewStatus(),
                            status: 'uncompleted'
                        });
                }
            } else if ($state.current.name === 'main.completedRouteStops') {
                $rootScope.$broadcast('showBusyIndicator');

                $state.go('main.routeStops',
                    {
                        orderId: $state.params.orderId,
                        sortBy: routeStopsService.getSortBy(),
                        splitView: routeStopsService.getSplitViewStatus(),
                        status: 'uncompleted'
                    });
            } else if ($state.current.name === 'main.settings') {
                $rootScope.$broadcast('showBusyIndicator');
            }
        }

        function support() {
            $kWindow.open({
                options: {
                    modal: true,
                    title: 'Support',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 150,
                    width: 350
                },
                templateUrl: 'app/support/support-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SupportController'
            });
        }

        function settings() {
            $kWindow.open({
                options: {
                    modal: true,
                    title: 'Innstillinger',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 150,
                    width: 350
                },
                templateUrl: 'app/settings/settings-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SettingsController'
            });
        }

        //show nearby routestops in a popup
        function showNearbyRouteStops() {
            cacheService.get(routeStopsCachePrefix + $state.params.orderId).then(function (route) {

                addedNearbyRouteStops = _.filter(route.routeStops, { 'isNearbyRouteStop': true });

                var nearbyRouteStopsWindow = $kWindow.open({
                    options: {
                        modal: true,
                        title: 'Tilfeldig registrering',
                        draggable: false,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 600
                    },
                    templateUrl: 'app/nearbyRouteStops/nearby-route-stops.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'NearbyRouteStopsController',
                    resolve: {
                        addedRouteStops: function () {
                            return addedNearbyRouteStops;
                        },
                        orderId: function() {
                            return $state.params.orderId;
                        }

                    }
                });

                nearbyRouteStopsWindow.result.then(function (rs) {
                    if (rs) {
                        $scope.showInrouteModal = false;
                        addedNearbyRouteStops.push(rs);
                        $rootScope.$broadcast('routeStopAddRequest', rs);
                    }
                });
            });
        }

        function callOrders() {
            callOrderWindowInstance = $kWindow.open({
                options: {
                    modal: true,
                    title: 'Anropsordre',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 400,
                    width: 600
                },
                templateUrl: 'app/callOrders/call-orders-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'callOrdersController'
            });
        }

        function messages() {
            messageWindowInstance = $kWindow.open({
                options: {
                    modal: true,
                    title: 'Meldinger',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 400,
                    width: 600
                },
                templateUrl: 'app/messages/messages-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'MessagesController'
            });
        }

        //Private functions
        function cancelOrderConfirmation() {
            $rootScope.cancelAlreadyConfirmed = confirm("Ønsker du virkelig å avbryte ruten?");

            return $rootScope.cancelAlreadyConfirmed;
        }

        function cancelOrderConfirmed() {
            $state.go('main.orders');

            hideModal();
        }

        function hideModal() {
            $('.modal').modal('hide');
            $('.modal-backdrop').remove();
        }
    }
})();
