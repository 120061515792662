(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('routeStopCard', routeStopCard);

    routeStopCard.$inject = ['$rootScope', '$kWindow', '$window', 'commonUtil', 'locationService', 'routeStopsService', 'settingsService', 'botekSensorListener', 'appSettings', 'orderItemTypeService'];

    function routeStopCard($rootScope, $kWindow, $window, commonUtil, locationService, routeStopsService, settingsService, botekSensorListener, appSettings, orderItemTypeService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/route-stop-card.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            $rootScope.$on('locationChanged', updateAccuracy);

            settingsService.getException1ButtonVisible().then(function (exception1ButtonVisible) {
                scope.isDeviation1ButtonVisible = exception1ButtonVisible;
            });

            scope.isServiceUser = $rootScope.userType === 'service';
            scope.adjustPositionAccuracyLimit = appSettings.routeStops.adjustPositionAccuracyLimit;

            scope.isAccuracyGood = isAccuracyGood;
            scope.isAjourUser = $rootScope.userType === 'ajour';

            scope.toggleSetPosition = function(evt, routeStop) {
                preventPropagation(evt);
                if (!isAccuracyGood()) {
                    alert('GPS signal er for unøyaktig til å sette posisjon.');
                    return;
                }
                $rootScope.$broadcast('showBusyIndicatorOverRouteStops', '');
                if (scope.deviceLongitude && scope.deviceLatitude && validateCoordinates(scope.deviceLongitude, scope.deviceLatitude)) {
                    routeStop.addOrUpdateLogData('DegLat', scope.deviceLatitude);
                    routeStop.addOrUpdateLogData('DegLon', scope.deviceLongitude);
                    routeStop.manuallySetPosition = !routeStop.manuallySetPosition;

                    routeStopsService.saveLocalRouteStop(scope.orderId, routeStop).then(() => {
                        $rootScope.$broadcast('hideBusyIndicatorOverRotueStops');
                    });
                } else {
                    $rootScope.$broadcast('hideBusyIndicatorOverRotueStops');
                }
            }

            scope.showEvents = function (evt, routeStop) {
                preventPropagation(evt);
                var height = Math.min($window.innerHeight, 500);
                height -= 37; // header

                $kWindow.open({
                    options: {
                        modal: true,
                        title: 'Logg',
                        draggable: false,
                        movable: false,
                        resizable: false,
                        visible: false,
                        maxHeight: height,
                        width: "90%",
                        position: {
                            top: "15%",
                            left: "5%"
                        },
                        close: function () {
                            $rootScope.$broadcast('hideBusyIndicator'); //hide indicator when closed with 'x' button
                        }
                    },
                    templateUrl: 'app/routeStops/modals/container-events-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'containerEventsModalController',
                    resolve: {
                        units: function () {
                            return routeStop.units;
                        }
                    }
                });
            }

            scope.showStatistics = function (evt, unit) {
                preventPropagation(evt);
                var width = Math.min($window.innerWidth - 50, 700);
                var height = Math.min($window.innerHeight, 500);
                height -= 37; // header

                if (unit && unit.containerId) {
                    $kWindow.open({
                        options: {
                            modal: true,
                            title: 'Statistikk',
                            draggable: false,
                            movable: false,
                            resizable: false,
                            visible: false,
                            height: height,
                            width: width
                        },
                        templateUrl: 'app/routeStops/modals/container-statistics-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ContainerStatisticsModalController',
                        resolve: {
                            containerId: function () {
                                return unit.containerId;
                            }
                        }
                    });
                }
            };

            scope.dataButtonClick = function (evt, routeStop, dataButton) {
                preventPropagation(evt);

                var windowOptions = {
                    modal: true,
                    title: dataButton.valueNO,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 410,
                    width: 400
                };

                var mobileDetect = new MobileDetect(window.navigator.userAgent);
                if (mobileDetect.is('AndroidOS')) {
                    windowOptions.animation = false;
                }

                //Open data button
                if (dataButton.valueENG === 'Weight') {
                    if ($rootScope.isWeightUnitBotek) {
                        if (!isiOSDevice()) {
                            botekSensorListener.startListening();
                        }
                        openBotekBleModal(routeStop, dataButton);
                    } else {
                        openWeightDataButtonModal(routeStop, dataButton);
                    }
                } else {
                    var windowInstance = $kWindow.open({
                        options: windowOptions,
                        templateUrl: 'app/routeStops/modals/data-button-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'DataButtonModalController',
                        resolve: {
                            dataButton: function () {
                                return dataButton;
                            }
                        }
                    });

                    windowInstance.result.then(function (result) {
                        if (result && result.action === 'save') {
                            var dataValue = correctFloatFormat(result.value);
                            dataButton.value = dataValue;
                            routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
                        }
                    });
                }

            };

            scope.addFirstDeviation = function (routeStop, firstDeviation) {
                _.forEach(routeStop.agreementLines, agreementLine => {
                    routeStop.deviations.push({ 'agreementLineId': agreementLine.agreementLineId.toString(), 'deviation': firstDeviation });
                });
                routeStop.successful = 'unsuccessful';
                routeStop.message = 'Nei';

                scope.handleRouteStopCompleted(routeStop);
            };

            scope.openVoltAdjust = function (evt) {
                preventPropagation(evt);

                if (scope.voltAdjustUrl) {
                    window.open(scope.voltAdjustUrl, '_blank');
                }
            };

            scope.showInfo = function (evt, routeStop) {
                preventPropagation(evt);

                let hasItemWithLongText = routeStop.orderData.some(item => {
                    if (!item.description || !item.value) {
                        return false;
                    }

                    return item.description.length > 20 || commonUtil.getOrderDataItemSanitizedValue(item).length > 15;
                });

                let modalOptions = {
                    modal: true,
                    title: 'Info',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    close: function () {
                        $rootScope.$broadcast('hideBusyIndicator'); //hide indicator when closed with 'x' button
                    }
                };

                if (hasItemWithLongText) {
                    modalOptions.width = '90%';
                }

                $kWindow.open({
                    options: modalOptions,
                    templateUrl: 'app/routeStops/modals/route-stop-info-view.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'RouteStopInfoController',
                    resolve: {
                        orderData: function () {
                            return routeStop.orderData;
                        },
                        routeStop: function () {
                            return routeStop;
                        }
                    }
                });
            };

            scope.showDirections = function (evt, routeStop) {
                preventPropagation(evt);

                locationService.getCurrentPosition().then(function (currentPosition) {
                    var directionLink = routeStop.getDirectionLink(currentPosition);
                    var win = undefined;
                    if (('standalone' in window.navigator) && window.navigator.standalone) {
                        win = window.open(directionLink, '_top');

                    } else {
                        win = window.open(directionLink, '_blank');
                    }
                    win.focus();

                });
            };

            scope.stopError = function (evt, routeStop) {
                preventPropagation(evt);
                if ($rootScope.noButtonRequiresDeviation) {
                    $rootScope.$broadcast('openDeviationWindow', evt, routeStop, true);
                } else {
                    routeStop.successful = 'unsuccessful';
                    routeStop.message = 'Nei';
                    routeStop.isAutoComplete = "False";
                    scope.handleRouteStopCompleted(routeStop);
                }
            };

            scope.stopApproved = function (evt, routeStop) {
                preventPropagation(evt);

                routeStop.isAutoComplete = "False";
                routeStop.successful = 'successful';
                routeStop.message = 'Ja';

                scope.handleRouteStopCompleted(routeStop);
            };

            scope.getWasteClass = function (wasteType) {

                var lowerCaseWasteType = wasteType ? wasteType.toLowerCase() : "pr";
                switch (lowerCaseWasteType) {
                    case "pr":
                        return "household-waste";
                    case "næ":
                        return "commercial-waste";
                    case "sl":
                        return "sludge-waste";
                    default:
                        return "household-waste";
                }
            };

            scope.updateUnitPosiiton = function (routeStop) {

                var unit = routeStop.units[0];
                routeStopsService.updateUnitCoordinates(unit.agreementLines[0], routeStop.system, unit.x, unit.y);
            }

            scope.deleteArticle = function (evt, routeStop, article) {
                evt.stopPropagation();
                evt.preventDefault();
                var index = _.findIndex(routeStop.articles, (a) => a.articleId === article.articleId);
                if (index > -1) {
                    routeStop.articles.splice(index, 1);
                }
                routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
            }

            scope.openAddArticle = function (evt, routeStop) {
                evt.stopPropagation();
                evt.preventDefault();

                scope.addArticleModal = $kWindow.open({
                    options: {
                        modal: true,
                        title: "Legg til Artikkel",
                        movable: false,
                        resizable: false,
                        visible: false,
                        height: '600',
                        width: '600'
                    },
                    templateUrl: 'app/articles/add-article-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'articlesController',
                    resolve: {
                        routeStop: function () {
                            return routeStop;
                        },
                        downloadedArticles: function () {
                            return scope.downloadedArticles;
                        }
                    }
                });

                scope.addArticleModal.result.then(function (routeStop) {
                    if (routeStop) {
                        routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
                    }
                });
                scope.addArticleModal = null;
            };

            scope.getOrderItemType = function (routeStop) {
                return orderItemTypeService.getOrderItemType(routeStop);
            }

            scope.isOrderItemTypeOut = function (routeStop) {
                return orderItemTypeService.isOrderItemTypeOut(routeStop);
            }

            function updateAccuracy(evt, position) {
                scope.gpsAccuracy = position.coords.accuracy;
                scope.accuracy = parseFloat(position.coords.accuracy).toLocaleString('no-NO', { maximumFractionDigits: 6 });
                scope.deviceLongitude = position.coords.longitude;
                scope.deviceLatitude = position.coords.latitude;
            }

            function isAccuracyGood() {
                return Number(scope.gpsAccuracy) <= scope.adjustPositionAccuracyLimit;
            }

            function validateCoordinates(long, lat) {
                if (long == null || lat == null) {
                    return false;
                }
                var correctLongitude = long >= -180 && long <= 180;
                var correctLatitude = lat > 0.1 && lat <= 90;
                return correctLongitude && correctLatitude;
            }

            function isiOSDevice() {
                var platform = navigator.platform;
                return platform === 'iPad' || platform === 'iPhone' || platform === 'iPod' || platform.includes('Mac');
            }

            function openBotekBleModal(routeStop, dataButton) {

                var botekWindowOptions = {
                    modal: true,
                    title: 'Bluetooth Enheter',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 460,
                    width: 400
                };


                var botekWindow = $kWindow.open({
                    options: botekWindowOptions,
                    templateUrl: 'app/routeStops/modals/weight-sensor-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'WeightSensorModalController',
                    resolve: {
                        routeStop: () => routeStop,
                        dataButton: () => dataButton
                    }
                });

                botekWindow.result.then((data) => {
                    if (data && data.weight && data.routeStop) {
                        var wButtons = _.filter(routeStop.dataButtons, db => db.identity === "Weight");
                        if (wButtons) {
                            var weightButton = wButtons[0];
                            weightButton.value = data.weight;
                            routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
                        }

                    }
                }).finally(() => {
                    botekSensorListener.stopListening();
                    $rootScope.isWeightSensorPollingCache = false;
                    $rootScope.$broadcast('hideBusyIndicator');
                });
            }

            function openWeightDataButtonModal(routeStop, dataButton) {

                var weightDataWindowOptions = {
                    modal: true,
                    title: 'Vekt',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 450,
                    width: 400
                };


                var weightDataWindow = $kWindow.open({
                    options: weightDataWindowOptions,
                    templateUrl: 'app/routeStops/modals/weight-data-button-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'WeightDataButtonModalController',
                    resolve: {
                        routeStop: function () {
                            return routeStop;
                        },
                        dataButton: function () {
                            return dataButton;
                        }
                    }
                });

                function saveUnitNumbers(routeStop, agreementLineIds, unitWeightValue) {
                    var unit = _.find(routeStop.units, u => u.agreementLines.join(',') === agreementLineIds);
                    if (unit) {
                        unit.numbers = unitWeightValue;
                    }
                }

                weightDataWindow.result.then(function (result) {
                    if (result && result.action === 'save') {

                        if (result.value.weightValues && result.value.weightValues.length > 0) {
                            var dataValues = "";
                            var weightSum = 0;
                            _.forEach(result.value.weightValues,
                                weightValue => {
                                    var dataValue = "";
                                    var agreementIds = weightValue.agreementLineIds.split(',');
                                    if (weightValue.value) {
                                        saveUnitNumbers(result.routeStop, weightValue.agreementLineIds, weightValue.value);
                                        dataValue = correctFloatFormat(weightValue.value);
                                        weightSum += Number(dataValue.replace(',', '.'));
                                    } else {
                                        dataValue = "null";
                                    }
                                    var i = 0;
                                    _.forEach(agreementIds, aLnId => {
                                        dataValues += aLnId + ":";
                                        dataValues += i === 0 ? (dataValue !== "" ? dataValue.replace('.', ',') : "null") : "null";
                                        dataValues += ";";
                                        i++;
                                    });

                                });

                            if (dataValues !== ":null;") {
                                var precision = 0;
                                if (result.value.anyUnitsEdited) {
                                    dataButton.values = dataValues;
                                }
                                if (dataValues.indexOf(',') > -1) {
                                    precision = 3;
                                }
                                dataButton.value = weightSum.toFixed(precision).toString().replace('.', ',');;

                                routeStopsService.saveLocalRouteStop(scope.orderId, routeStop);
                            }

                        }

                    }
                });
            }

            function correctFloatFormat(data) {
                var dataValue = data;
                if (dataValue.indexOf(',') === 0) {
                    dataValue = '0' + dataValue;
                }

                if (dataValue.indexOf(',') === dataValue.length - 1) {
                    dataValue = dataValue.substring(0, dataValue.length - 1);
                }
                return dataValue;
            }

            function preventPropagation(evt) {
                if (evt) {
                    evt.stopPropagation();
                    evt.preventDefault();
                }
            }

            function addContainerId(routeStop) {
                if ($rootScope.userType === 'service') {
                    return " - #" + routeStop.units[0].containerId;
                }
                return "";
            }

            function getSanitizedValue(orderDataItem) {
                if (!orderDataItem || orderDataItem.value == undefined) {
                    return '';
                }

                if (orderDataItem.key === '[agreementLine_LastDate]' || orderDataItem.key === '[agreementLine_NextDate]') {
                    if (orderDataItem.value === '01/01/1900 00:00:00') {
                        return '';
                    }

                    var time = moment(orderDataItem.value, 'MM/DD/YYYY hh:mm:ss').format('DD/MM/YYYY');
                    return time;
                } else {
                    if (orderDataItem.value.toLowerCase() === 'true') {
                        return 'Ja';
                    }
                    else if (orderDataItem.value.toLowerCase() === 'false') {
                        return 'Nei';
                    }
                    return orderDataItem.value;
                }
            }


        }
    }
})();
