(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('locationService', locationService);

    locationService.$inject = ['$q', '$rootScope', '$interval', '$timeout', 'appSettings'];

    function locationService($q, $rootScope, $interval, $timeout, appSettings) {
        var locationWatchId,
            currentPosition,
            lastPosition,
            locationExpireTimer;

        var locationService = {
            getCurrentPosition: getCurrentPosition
        };

        initService();

        return locationService;

        function initService() {
            getPosition().then(function(position) {
                currentPosition = position;
                lastPosition = position;

                var options = { enableHighAccuracy: true };
                if (navigator.geolocation) {
                    locationWatchId = navigator.geolocation.watchPosition(locationChanged, function () { }, options);
                }

                //Poll curent position while listening to watchPosition
                $interval(function () {
                    getPosition().then(locationChanged);
                }, appSettings.getPositionIntervalMs);

                locationExpireTimer = $timeout(locationExpired, appSettings.locationExpireMs);
            });
        }

        function getCurrentPosition() {
            var deferred = $q.defer();

            if (currentPosition) {
                deferred.resolve(currentPosition);
            } else {
                getPosition().then(function (position) {
                    currentPosition = position;

                    deferred.resolve(position);
                }, function(error) {
                    deferred.reject(error);
                });
            }

            return deferred.promise;
        }

        //Private functions
        function getPosition() {
            var deferred = $q.defer(),
                options = {
                    enableHighAccuracy: true,
                    maximumAge: 20000,
                    timeout: 10000
                };

            navigator.geolocation.getCurrentPosition(function (position) {
                deferred.resolve(position);
            }, function (error) {
                deferred.reject(error);
            }, options);

            return deferred.promise;
        }

        function calculateSpeed(firstPosition, secondPosition) {
            var distance = getDistance(firstPosition.coords, secondPosition.coords);

            var elapsedTime;
            if (firstPosition.timestamp > secondPosition.timestamp) {
                elapsedTime = firstPosition.timestamp - secondPosition.timestamp;
            } else {
                elapsedTime = secondPosition.timeStamp - firstPosition.timestamp;
            }

            if (elapsedTime < 1) {
                return 0;
            }

            return Math.round((distance / 1000) / (elapsedTime / 1000 / 60 / 60));
        }

        function getDistance(from, to) {
            return geolib.getDistance(getCoordinateValues(from), getCoordinateValues(to), 1, 1);
        }

        function getCoordinateValues(coord) {
            return { "latitude": coord.latitude, "longitude": coord.longitude };
        }

        function locationChanged(position) {
            $timeout.cancel(locationExpireTimer);

            lastPosition = currentPosition;
            currentPosition = position;

            //if (position.speed === undefined || position.speed === null) {
            //    position.speed = calculateSpeed(currentPosition, lastPosition);
            //}

            $rootScope.$broadcast('locationChanged', position);

            locationExpireTimer = $timeout(locationExpired, appSettings.locationExpireMs);
        }

        function locationExpired() {
            $rootScope.$broadcast('locationExpired');
        }
    }
})();
