(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('locationNumPad', locationNumPad);

    locationNumPad.$inject = [];


    function locationNumPad() {
        var directive = {
            controller: ['$rootScope', '$scope', 'locationService', locationNumPad],
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: 'app/shared/directives/location-num-pad.html'
        };

        return directive;

     
        function locationNumPad($rootScope, $scope, locationService) {
            locationService.getCurrentPosition().then(function (position) {
                var pos = {
                    coords: {
                        accuracy: position.coords.accuracy,
                        longitude: position.coords.longitude,
                        latitude: position.coords.latitude,
                        speed: position.coords.speed
                    }
                };
                $scope.fakePosition = pos;
            });
            document.addEventListener("keydown", keypress_handler, false);

            function keypress_handler(e) {
                if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
                    // 0-9 only
                    switch(e.keyCode) {
                        case 97:
                        {
                            $scope.move('down-left');
                            break;
                        }
                        case 98:
                        {
                            $scope.move('down');
                            break;
                        }
                        case 99:
                        {
                            $scope.move('down-right');
                            break;
                        }
                        case 100:
                        {
                            $scope.move('left');
                            break;
                        }
                        case 102:
                        {
                            $scope.move('right');
                            break;
                        }
                        case 103:
                        {
                            $scope.move('up-left');
                            break;
                        }
                        case 104:
                        {
                            $scope.move('up');
                            break;
                        }
                        case 105:
                        {
                            $scope.move('up-right');
                            break;
                        }
                        default:
                        {
                          
                        }
                    }
                }
            }


            $scope.move = function (direction) {

                    var pos = $scope.fakePosition;

                    if (!$scope.accuracy) {
                        $scope.accuracy = 10;
                    }
                    if (!$scope.speed) {
                        $scope.speed = 1;
                    }
                    if (!$scope.delta) {
                        $scope.delta = 0.0001;
                    }
                    if (!$scope.delay) {
                        $scope.delay = 0;
                    }

                    pos.coords.accuracy = Number($scope.accuracy);
                    pos.coords.speed = Number($scope.speed);
                    var delta = Number($scope.delta);

                    switch (direction) {
                    case 'up':
                    {
                        pos.coords.latitude += delta;
                        break;
                    }
                    case 'left':
                    {
                        pos.coords.longitude -= delta;
                        break;
                    }
                    case 'up-left':
                    {
                        pos.coords.latitude += delta;
                        pos.coords.longitude -= delta;
                        break;
                    }
                    case 'up-right':
                    {
                        pos.coords.longitude += delta;
                        pos.coords.latitude += delta;
                        break;
                    }
                    case 'right':
                    {
                        pos.coords.longitude += delta;
                        break;
                    }
                    case 'down':
                    {
                        pos.coords.latitude -= delta;
                        break;
                    }
                    case 'down-right':
                    {
                        pos.coords.longitude += delta;
                        pos.coords.latitude -= delta;
                        break;
                    }
                    case 'down-left':
                    {
                        pos.coords.latitude -= delta;
                        pos.coords.longitude -= delta;
                        break;
                    }
                    default:
                    }
                    if ($scope.delay && $scope.delay > 0) {
                        setTimeout(() => $rootScope.$broadcast('locationChanged', pos), Number($scope.delay));
                    }else {
                        $rootScope.$broadcast('locationChanged', pos);
                    }
               


            }


        }
    }
})();
